import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from '../../Components/Message/Message';
import { Button, Grid, Header, Message } from 'semantic-ui-react'
import queryString from 'query-string';
import Logger from '../../Helper/Logger';

class ConfirmEmail extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            isSuccess:false,
            description:'',
            messageType:'info',
            messageShow:false,
            message:'',
            notification:'Please wait for a second. Thank you for your understanding',
            notificationShow:false,
            param:{
                id:'',
                token:''
            },
            goToContact:false,
            unknownError:false
        };

        this.toggleMessage = this.toggleMessage.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.requestAgain = this.requestAgain.bind(this);
        this.goContactUs = this.goContactUs.bind(this);
    }

    get displayName() {
        return 'Logout';
    }

    goContactUs(){
        this.setState({goToContact:true});
    }

    toggleMessage(mes)
    {
        let {messageType, messageShow, message} = this.state;

        messageType = mes.type;
        messageShow = mes.show;
        message = mes.message;
        
        this.setState({
            message:message,
            messageShow:messageShow,
            messageType:messageType
        });
    }

    handleClose()
    {
        this.setState({
            messageShow:false
        })
    }
    
    async componentDidMount() 
    {
        let params = queryString.parse(this.props.location.search);

        let param = this.state.param;

        if(params.id && params.token)
        {
            param.id = params.id;
            param.token = params.token;
            this.setState({param:param})

            const url = process.env.REACT_APP_API_URL + "api/Users/ConfirmEmail";

            const s = {
                id: params.id,                
                token:params.token,
                Description:'',
                isSuccess:false
            };
            
            await fetch(url, {
                method:"POST",
                mode: 'cors',
                headers:{
                    "Content-Type": "application/json"
                },
                body:JSON.stringify(s)
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();                    
                } else {
                    let s = {
                        message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                        type:'error',
                        show:true
                    }
        
                    this.toggleMessage(s); 

                    Logger('ConfirmEmail', 'componentDidMount', response.status + response.statusText + response.url , localStorage.getItem("email"));
                }
            })
            .then((data) => {
                if(data.isSuccess)
                {
                    this.setState({
                        message:"Cograturations! Your account has been activated!",
                        type:'success',
                        messageShow:true                    
                    });                      
                    
                    setTimeout(() => { this.setState({ isSuccess: true }); }, 5000);                 
                }
                else
                {
                    let result = "";
                    if(data.Description)
                    {
                        data.Description.forEach(d => {
                            result = result + d;
                        });
                    }

                    let s = {
                        message:result,
                        type:'error',
                        show:true
                    }        
                    this.toggleMessage(s); 
                    this.setState({notificationShow:true});
                }

            })
            .catch(err => {
                //this.props.onError(err.message, true, true);
                let s = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                }
    
                this.toggleMessage(s); 
                this.setState({notificationShow:true});

                Logger('ConfirmEmail', 'componentDidMount', 'error message ' + err , localStorage.getItem("email"));
            });
        }
        else{
            let s = {
                message:'Please check your email again',
                type:'error',
                show:true
            }

            this.toggleMessage(s);
            this.setState({
                notificationShow:false,
                notification:'Please check your email again',
                unknownError:true
            });
        }
    }  

    async requestAgain()
    {
        let param = this.state.param;

        if(param.id && param.token)
        {
            const url = process.env.REACT_APP_API_URL + "api/Users/ResendValidationEmail";

            const s = {
                id: param.id,                
                token:param.token,
                Description:'',
                isSuccess:false
            };
            
            await fetch(url, {
                method:"POST",
                mode: 'cors',
                headers:{
                    "Content-Type": "application/json"
                },
                body:JSON.stringify(s)
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();                    
                } else {
                    let s = {
                        message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                        type:'error',
                        show:true
                    }
        
                    this.toggleMessage(s); 
                    this.setState({notificationShow:true});

                    Logger('ConfirmEmail', 'requestAgain', response.status + response.statusText + response.url , localStorage.getItem("email"));
                }
            })
            .then((data) => {
                
                if(data.isSuccess)
                {
                    this.setState({
                        message:"Cograturations! Your account has been activated!",
                        type:'success',
                        messageShow:true                      
                    });                      
                    
                    setTimeout(() => { this.setState({ isSuccess: true }); }, 5000);
                }
                else
                {
                    let result = "";
                    if(data.Description)
                    {
                        data.Description.forEach(d => {
                            result = result + d;
                        });
                    }

                    let s = {
                        message:result,
                        type:'error',
                        show:true
                    }        
                    this.toggleMessage(s); 
                    this.setState({notificationShow:true});
                }
            })
            .catch(err => {
                //this.props.onError(err.message, true, true);
                let s = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                }

                this.toggleMessage(s); 
                this.setState({notificationShow:true});

                Logger('ConfirmEmail', 'requestAgain', 'error message ' + err , localStorage.getItem("email"));
            });

        }
        else{

            let s = {
                message:'Please check your email again',
                type:'error',
                show:true
            }

            this.toggleMessage(s);
            this.setState({
                notificationShow:false,
                notification:'Please check your email again',
                unknownError:true
            });
        }
    }

    render(){
        let {message, messageType, messageShow, notification, notificationShow, unknownError} = this.state;
        return (   
            <div>                                
                <div>
                    {this.state.isSuccess? <Redirect to='/' /> : <div></div>}
                    {this.state.goToContact? <Redirect to='/Support' /> : <div></div>}
                    <br/><br/><br/>
                    <Grid textAlign='center' style={{ height: '10vh' }} verticalAlign='top'>
                        <Grid.Row>
                            <Grid.Column style={{ maxWidth: 500 }}>
                                <Header as='h2' color='teal' textAlign='center'>
                                We are activating your accout. <br/><br/>
                                {notification}<br/><br/>
                                </Header>                
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                            {notificationShow && 
                            <Message warning>
                                <Message.Header>Please try it again!</Message.Header>
                                <p></p>
                                <p>What's happening?</p>
                                <p>In most cases, when your email validation link gets expired <br/><br/>
                                you can see this. You can solve this by requesting email validation link again.<br></br><br></br>
                                Click button below to request email validation link again.
                                </p>                                
                                <Button primary onClick={this.requestAgain}>
                                    Request Email Validation
                                </Button>
                                <br/><br/><br/>
                                <p>When this continues to happen, please ask help</p>
                                <br/><br/>
                                <Button basic color='blue' content='contact us' primary onClick={this.goContactUs} />
                                <br/><br/>
                            </Message>
                            }
                            {unknownError && 
                            <Message negative>
                                <p></p>
                                <p>We have failed to confirm you email address<br/><br/>
                                Please check your email again and click the validation link. 
                                </p>                                
                                <br/><br/>
                                <p>When this continues to happen, please ask help</p>
                                <br/><br/>
                                <Button  color='yellow' content='contact us' onClick={this.goContactUs} />
                                <br/><br/>
                            </Message>
                            }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>                    
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={messageShow}
                    autoHideDuration={6000}
                    onClose={this.handleClose}>
                        <SnackbarContentWrapper
                                onClose={this.handleClose}
                                variant={messageType}
                                message={message}
                        />
                </Snackbar>  
            </div>
        );
    }
};

export default withRouter(ConfirmEmail);