import React from 'react';
import { Button, Form, Grid, Header, Message, Segment, Loader, Icon } from 'semantic-ui-react'
import { PasswordValidation} from '../TextboxValidator/EmailValidation';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from '../../Components/Message/Message';
import Modal from 'react-bootstrap/Modal';
import {withRouter, Redirect} from 'react-router-dom';
import queryString from 'query-string';
import Logger from '../../Helper/Logger';

class PasswordResetForm extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            confirmPassword:'',
            password:'',
            GoToMain:false,
            passwordValid:true,
            messageType:'info',
            messageShow:false,
            message:'',
            successShow:false,
            buttonDisable:true,
            spinner:false,
            equalPassword:true,
            param:{
                id:'',
                token:''
            },
        };
        
        this.handleChanged = this.handleChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleMessage = this.toggleMessage.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.goToMain = this.goToMain.bind(this);
        this.buttonToggle = this.buttonToggle.bind(this);
        this.isEqual = this.isEqual.bind(this);
    }

    get displayName() {
        return 'PasswordResetForm';
    }

    componentDidMount() {
        
        let params = queryString.parse(this.props.location.search);
        let param = this.state.param;

        param.id = params.id;
        param.token = params.token;
        this.setState({param:param})
    }

    handleChanged(event)
    {
        this.setState({
            [event.target.id]: event.target.value
        });

        if(event.target.id === 'confirmPassword')
        {
            this.setState({confirmPassword:event.target.value}, this.isEqual)            
        } 

        if(event.target.id === 'password')
        {
            this.setState({passwordValid:PasswordValidation(event.target.value)}, this.buttonToggle)
        }
    }

    isEqual()
    {
        const {password, confirmPassword} = this.state;

        if(password === confirmPassword)
        {
            this.setState({equalPassword:true}, this.buttonToggle);
        }
        else{
            this.setState({equalPassword:false});
        }
    }

    buttonToggle()
    {
        const {confirmPassword, password } = this.state;

        if(confirmPassword && password && confirmPassword.length > 0 && password.length > 0)
        {
            this.setState({buttonDisable:false});
        }
        else{

            this.setState({buttonDisable:true});
        }
    }

    toggleMessage(mes)
    {
        let {messageType, messageShow, message} = this.state;

        messageType = mes.type;
        messageShow = mes.show;
        message = mes.message;
        
        this.setState({
            message:message,
            messageShow:messageShow,
            messageType:messageType, 
            spinner:false
        });
    }

    handleClose()
    {
        this.setState({
            messageShow:false
        });
    }

    goToMain()
    {
        //redirect to login page
        this.setState({GoToMain:true});
    }

    async handleSubmit(event)
    {
        event.preventDefault();
        this.setState({
            buttonDisable:true,
            spinner:true
        });

        let { param, password } = this.state;        
           
        const url = process.env.REACT_APP_API_URL + "Controllers/User/ResetPassword.php";
        const s = {
            password: password,
            id: param.id,                
            token:param.token,
            Description:'',
            isSuccess:false
        };

        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json"
            },
            body:JSON.stringify(s)
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                let s = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                }        
                this.toggleMessage(s); 

                Logger('PasswordReset', 'handleSubmit', response.status + response.statusText + response.url , localStorage.getItem("email"));
            }
        })
        .then((data) => {
            if(data.isSuccess)
            {
                localStorage.setItem('email', data.email);
                localStorage.setItem('token', data.token);

                this.setState({
                    message:"Cograturations! Password reset has successfully completed!",
                    successShow:true
                }); 
            }
            else
            {
                let result = "";
                if(data.Description)
                {
                    data.Description.forEach(d => {
                        result = result + d;
                    });
                }

                let s = {
                    message:result,
                    type:'error',
                    show:true
                }        
                this.toggleMessage(s); 
            }
        })
        .catch(err => {
            let s = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }    
            this.toggleMessage(s); 
        });
            
        
    }

    render(){        
        let {message, messageType, messageShow, successShow, GoToMain, buttonDisable, spinner} = this.state;
        let redirectComponent;
        
        if(GoToMain)
        {
            redirectComponent = <Redirect to='/' />;
        }
        else
        {
            redirectComponent = <div></div>;
        }
        return (   
            <div>   
                {redirectComponent}    
                <br/><br/><br/> 
                <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='top'>
                    <Grid.Row>
                        <Grid.Column style={{ maxWidth: 500 }}>
                        <Header as='h2' color='teal' textAlign='center'>
                            Reset your password here<br/><br/>
                            <br/><br/>
                            Password should have at least<br></br> 1 upper case character, 1 digit and 1 non alphanumeric character<br/><br/>
                            
                            비밀번호를 초기화 하세요.
                            <br/><br/>
                            비밀번호는 6자 이상이어야 하며, <br></br>최소한 1자 씩의 대문자, 숫자, 특수문자를<br></br> 포함해야합니다.
                        </Header> 
                        <Form size='large' onSubmit={this.handleSubmit}>                
                            <Segment stacked>
                            <Form.Input
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Password'
                                type='password'
                                id="password" 
                                name="password" 
                                onChange={this.handleChanged}
                                value={this.state.password}
                            />
                            <Form.Input
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Confirm Password'
                                type='password'
                                id="confirmPassword" 
                                name="confirmPassword" 
                                onChange={this.handleChanged}
                                value={this.state.confirmPassword}
                            />     
                            {!this.state.passwordValid &&
                                <Message  color='red'>
                                    Password should have at least 1 upper case character, 1 digit and 1 non alphanumeric character<br/>
                                    비밀번호는 6자 이상이어야 하며, 최소한 1자 씩의 대문자, 숫자, 특수문자를 포함해야합니다.
                                </Message>
                            }
                            {!this.state.equalPassword &&
                                <Message  color='red'>
                                    Two Passwords do not match<br/>
                                    두 비밀번호가 일치 하지 않습니다.
                                </Message>
                            }
                            <Loader active={spinner} />
                            <Button color='teal' fluid size='large' disabled={buttonDisable}>Password Reset</Button>                              
                            </Segment>
                            </Form>                           
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                
                <Modal show={successShow} onHide={this.goToMain} aria-labelledby="contained-modal-title-vcenter">  
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        <Icon name='check circle outline' color='green' /> Successful!
                        </Modal.Title>
                    </Modal.Header>                          
                    <Modal.Body>
                    
                    <Message info>
                        <Message.Header>{message}</Message.Header>
                    </Message>   
                    <Button primary onClick={this.goToMain}>
                    Go to Main page
                    </Button>   
                    </Modal.Body>
                </Modal>
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={messageShow}
                    autoHideDuration={6000}
                    onClose={this.handleClose}>
                        <SnackbarContentWrapper
                                onClose={this.handleClose}
                                variant={messageType}
                                message={message}
                        />
                </Snackbar>  
            </div>
        );
    }
};

export default withRouter(PasswordResetForm);