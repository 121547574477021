import React from 'react';
import { Button, Form, Grid, Message, Segment, Loader } from 'semantic-ui-react'
import { PasswordValidation} from '../TextboxValidator/EmailValidation';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from '../Message/Message'
import {withRouter} from 'react-router-dom';
import Logger from '../../Helper/Logger';

class ChangePasswordForm extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            password:'',
            newPassword:'',
            confirmPassword:'',
            passwordValid:true,
            messageType:'info',
            messageShow:false,
            message:'',
            successShow:false,
            buttonDisable:true,
            spinner:false,
            equalPassword:true
        };
        
        this.handleChanged = this.handleChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleMessage = this.toggleMessage.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.buttonToggle = this.buttonToggle.bind(this);
    }

    get displayName() {
        return 'ChangePasswordForm';
    }

    handleChanged(event)
    {
        this.setState({
            [event.target.id]: event.target.value
        });

        if(event.target.id === 'confirmPassword')
        {
            this.isEqual(event.target.value);          
        } 

        if(event.target.id === 'newPassword')
        {
            this.setState({
                passwordValid:PasswordValidation(event.target.value),
                confirmPassword:''
            });
        }        
    }

    isEqual(value)
    {
        const {newPassword} = this.state;
        
        if(newPassword === value)
        {
            this.setState({equalPassword:true}, this.buttonToggle);
        }
        else{
            this.setState({equalPassword:false});
        }
    }

    buttonToggle()
    {
        const {password, passwordValid, newPassword, confirmPassword, equalPassword} = this.state;

        if(equalPassword && passwordValid && confirmPassword.length > 0 && newPassword.length > 0 && password.length > 0)
        {
            this.setState({buttonDisable:false});
        }
        else{

            this.setState({buttonDisable:true});
        }

    }

    toggleMessage(mes)
    {
        let {messageType, messageShow, message} = this.state;

        messageType = mes.type;
        messageShow = mes.show;
        message = mes.message;
        
        this.setState({
            message:message,
            messageShow:messageShow,
            messageType:messageType, 
            spinner:false
        });
    }

    handleClose()
    {
        this.setState({
            messageShow:false,
            successShow:false
        });
    }
   
    async handleSubmit(event)
    {
        event.preventDefault();
        this.setState({
            buttonDisable:true,
            spinner:true
        });

        let { password, newPassword } = this.state;        
           
        const url = process.env.REACT_APP_API_URL + "Controllers/User/ChangePassword.php";
        const s = {
            password: password,
            id: newPassword,  
            Description:'',
            isSuccess:false,
            email:localStorage.getItem("email")
        };

        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body:JSON.stringify(s)
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                let s = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                }        
                this.toggleMessage(s); 

                Logger('ChangePasswordForm', 'handleSubmit', response.status + response.statusText + response.url , localStorage.getItem("email"));
            }
        })
        .then((data) => {
            if(data.isSuccess)
            {
                localStorage.setItem('token', data.token);

                this.setState({
                    password:'',
                    newPassword:'',
                    confirmPassword:'',
                    spinner:false,
                    equalPassword:true,
                    buttonDisable:true
                }); 

                let s = {
                    message:"Successfully completed! Please Login again",
                    type:'success',
                    show:true
                }        
                this.toggleMessage(s); 

                //localStorage.clear(); 
            }
            else
            {
                let result = "";
                if(data.Description)
                {
                    data.Description.forEach(d => {
                        result = result + d;
                    });
                }

                let s = {
                    message:result,
                    type:'error',
                    show:true
                }        
                this.toggleMessage(s); 

                this.setState({
                    password:'',
                    newPassword:'',
                    confirmPassword:'',
                    spinner:false,
                    equalPassword:true,
                    buttonDisable:true
                }); 
            }
        })
        .catch(err => {
            let s = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }    
            this.toggleMessage(s); 

            this.setState({
                password:'',
                newPassword:'',
                confirmPassword:'',
                spinner:false,
                equalPassword:true,
                buttonDisable:true
            }); 

            Logger('ChangePasswordForm', 'handleSubmit', 'error message ' + err , localStorage.getItem("email"));
        });
    }

    render(){        
        let {message, messageType, messageShow, buttonDisable, spinner} = this.state;
       
        return (   
            <div>    
                <Loader active={spinner} />
                <Grid textAlign='center' verticalAlign='top'>
                    <Grid.Row>
                        <Grid.Column style={{ maxWidth: 500 }}>
                        <Form size='large' onSubmit={this.handleSubmit}>                
                            <Segment stacked>
                            <Form.Input
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Current Password'
                                type='password'
                                id="password" 
                                name="password" 
                                onChange={this.handleChanged}
                                value={this.state.password}
                            />
                            <Form.Input
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='New Password'
                                type='password'
                                id="newPassword" 
                                name="newPassword" 
                                onChange={this.handleChanged}
                                value={this.state.newPassword}
                            />
                            <Form.Input
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Retype Password'
                                type='password'
                                id="confirmPassword" 
                                name="confirmPassword" 
                                onChange={this.handleChanged}
                                value={this.state.confirmPassword}
                            />     
                            {!this.state.passwordValid &&
                                <Message  color='red'>
                                    Password should have at least 1 upper case character, 1 digit and 1 non alphanumeric character<br/>
                                    비밀번호는 6자 이상이어야 하며, 최소한 1자 씩의 대문자, 숫자, 특수문자를 포함해야합니다.
                                </Message>
                            }
                            {!this.state.equalPassword &&
                                <Message  color='red'>
                                    Both Passwords do not match<br/>
                                    두 비밀번호가 일치 하지 않습니다.
                                </Message>
                            }
                            <Loader active={spinner} />
                            <Button color='teal' fluid size='large' disabled={buttonDisable}>Change Password</Button>                              
                            </Segment>
                            </Form>                           
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={messageShow}
                    autoHideDuration={6000}
                    onClose={this.handleClose}>
                        <SnackbarContentWrapper
                                onClose={this.handleClose}
                                variant={messageType}
                                message={message}
                        />
                </Snackbar>  
            </div>
        );
    }
};

export default withRouter(ChangePasswordForm);