import React from 'react';
import PurchaseHistory from './Purchase';
import ChangePasswordForm from './ChangePasswordForm';
import Unregister from './Unregister';
import { Segment, Divider, Item, Accordion, Icon } from 'semantic-ui-react'

import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import QuestionList from '../Question/QuestionList';
import "./Mypage.css";
import Logger from  '../../Helper/Logger';

class MyPage extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: false,
            activeIndex:0,
            isPaidUser: false
        };  

        this.loginChanged = this.loginChanged.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.getPaidUser = this.getPaidUser.bind(this); 
    }

    componentDidMount() 
    {
        if(!this.state.isLoggedIn && this.props.isLoggedIn)
        {
            this.setState({isLoggedIn:true});            
        }

        this.getPaidUser();
    }

    componentWillReceiveProps(nextProps)
    {
        if(!this.state.isLoggedIn && nextProps.isLoggedIn)
        {
            this.setState({isLoggedIn:true});
        }
    } 

    handleClick(event, titleProps)
    {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    loginChanged(status)
    {
        this.setState({isLoggedIn:status}, this.props.statusChanged(status));
    }

    async getPaidUser()
    {
        if(localStorage.getItem("token") === null) return;

        this.setState({
            buttonDisable:true,
            isProcessing:true,
            spinner:true            
        });

        const url = process.env.REACT_APP_API_URL + "Controllers/User/PaidUserCheck.php";
        const s = {
            email: localStorage.getItem("email"),
            token:'',
            Description:'',
            isSuccess:false
        };

        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body:JSON.stringify(s)
        })
        .then((response) => {   
           
            if (response.ok) {
                return response.json();
            } else {   
                this.setState({
                    isPaidUser:false
                });         
                
            }
        })
        .then((data) => {
            
            this.setState({
                spinner:false
            }); 

            if(data !== undefined || data !== 'undefined' || typeof data !== 'undefined' || typeof data !== undefined)
            {
                if(data && data.isSuccess) 
                {
                    //checkValue = true;  
                   
                    this.setState({
                        isPaidUser:true
                    }); 
                }
                else
                {
                    this.setState({
                        isPaidUser:false
                    }); 
                }
            }
            else{
                this.setState({
                    isPaidUser:false
                });
            }   
            
        })
        .catch(err => {
            
            Logger('getPaidUser-catch in mypage', 'getPaidUser-catch', 'error message ' + err , localStorage.getItem("email"));

            this.setState({
                isPaidUser:false
            });           
        });
    }  

    render(){
        let {isLoggedIn, activeIndex, isPaidUser} = this.state;
        return (   
            <div>
            {(isLoggedIn) ? ( 
                <div>
                    <br></br>
                    <br></br>
                    <table className="main_container">
                        <tbody>
                            <tr>
                                <td align="center">   
                                <Card style={{width: "80%"}}>
                                    <Card.Body>                       
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td valign="top">
                                                    <Icon name='payment' color='blue'/>
                                                </td>
                                                <td width="800" align="left" valign="top">
                                                    <Accordion >
                                                        <Accordion.Title
                                                            active={activeIndex === 1}
                                                            index={1}
                                                            onClick={this.handleClick}
                                                        >
                                                            <b>구매 이력</b>
                                                        </Accordion.Title>
                                                        <Accordion.Content  active={activeIndex === 1}>     
                                                            <PurchaseHistory />
                                                            <br></br>                                
                                                            <p>* 기존 구매 상품이 만료되기 전에 새로운 상품을 구입하시면 기존 만료일에서 연장되어 계산됩니다.</p>
                                                            <br></br> 
                                                        </Accordion.Content>
                                                    </Accordion>
                                                </td> 
                                                <td valign="top"><Icon name='caret down' color='black'/></td>                               
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><Divider /> </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td valign="top">
                                                    <Icon name='help' color='blue'/>
                                                </td>
                                                <td width="700" align="left" valign="top">
                                                    <Accordion >
                                                        <Accordion.Title
                                                            active={activeIndex === 2}
                                                            index={2}
                                                            onClick={this.handleClick}
                                                        >                                            
                                                            <b>내가 등록한 질문</b>                                                                                
                                                        </Accordion.Title>
                                                        <Accordion.Content  active={activeIndex === 2}>
                                                            <QuestionList onLoad={true} paidUser={isPaidUser} isMy={true}/>
                                                            <br></br>                                            
                                                        </Accordion.Content>
                                                    </Accordion>
                                                </td>
                                                <td valign="top"><Icon name='caret down' color='black'/></td> 
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><Divider /> </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td valign="top">
                                                    <Icon name='spy' color='blue'/>
                                                </td>
                                                <td width="700" align="left" valign="top">
                                                    <Accordion >
                                                        <Accordion.Title
                                                            active={activeIndex === 3}
                                                            index={3}
                                                            onClick={this.handleClick}
                                                        >                                            
                                                            <b>비밀번호 변경</b>                                                                               
                                                        </Accordion.Title>
                                                        <Accordion.Content  active={activeIndex === 3}>
                                                            강력한 비밀번호를 가지는 것은 언제나 옳습니다. <br></br>
                                                            <Divider />                                            
                                                            <Card>
                                                                <Card.Body>
                                                                    <ChangePasswordForm />
                                                                </Card.Body>
                                                            </Card>
                                                            <br></br> 
                                                        </Accordion.Content>
                                                    </Accordion>
                                                </td>
                                                <td valign="top"><Icon name='caret down' color='black'/></td> 
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><Divider /> </td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td valign="top">
                                                    <Icon name='user cancel' color='blue'/>
                                                </td>
                                                <td width="700" align="left" valign="top">
                                                    <Accordion >
                                                        <Accordion.Title
                                                            active={activeIndex === 4}
                                                            index={4}
                                                            onClick={this.handleClick}
                                                        >                                            
                                                            <b>탈퇴</b>                                                                                
                                                        </Accordion.Title>
                                                        <Accordion.Content  active={activeIndex === 4}>
                                                            회원 탈퇴를 원하시나요?<br></br>
                                                            <Divider />     
                                                            <Card>
                                                                <Card.Body>
                                                                    <Unregister statusChanged={this.loginChanged}/>
                                                                </Card.Body>
                                                            </Card>    
                                                            <br></br>                                  
                                                        </Accordion.Content>
                                                    </Accordion>
                                                </td>
                                                <td valign="top"><Icon name='caret down' color='black'/></td> 
                                            </tr>
                                        </tbody>
                                    </table>
                                    </Card.Body>
                                    </Card>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            ):(
                <div>
                    <br></br>
                <Segment color='purple' placeholder>                    
                <Item>
                <Item.Content>
                    <Item.Header as='a'>
                        <div className="item_title">Log in is necessary for this page</div>
                        <Divider />
                    </Item.Header>
                    <Item.Description>
                <div className="content_body">
                    <br></br><br></br>
                    <p>Please <b><u><Link to="/Authentication">Log In here</Link></u></b></p>
                    <p>New to us? Why don't you <Link to="/SignUp"><b><u>Sign Up</u></b></Link> now?</p>
                </div>
                </Item.Description>
                </Item.Content>
                </Item>
                </Segment> 
                </div>
            )}  
            </div>          
        );
    }
};

export default MyPage;

