import React from 'react';
import MyPage from "../../Components/MyPage/MyPage";

class MyPageContainer extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: false
        };
        
        this.loginChanged = this.loginChanged.bind(this);
    }

    componentDidMount() 
    {
        if(!this.state.isLoggedIn && this.props.isLoggedIn)
        {
            this.setState({isLoggedIn:true});
        }
    }

    componentWillReceiveProps(nextProps)
    {
        if(!this.state.isLoggedIn && nextProps.isLoggedIn)
        {
            this.setState({isLoggedIn:true});
        }
    }

    loginChanged(status)
    {
        this.setState({isLoggedIn:status}, this.props.statusChanged(status));
    }

    render(){
        let {isLoggedIn} = this.state;
        return (   
            <div>  
            <MyPage isLoggedIn={isLoggedIn} statusChanged={this.loginChanged}/>
                
            </div>
        );
    }
};

export default MyPageContainer;