import React from 'react';
import './MainContent.css';
import PaypalLogo from '../../images/payPalLogo.svg';
import { Icon } from 'semantic-ui-react'

 
class MainContent extends React.Component{

    GoToPayPay(){
        window.open('https://www.paypal.com/kr/webapps/mpp/pay-online?locale.x=ko_KR');
    }

    render(){
        return (   
            <div>
                <div className="main_content_dark_m"></div>
                <div className="main_content_blue_m">
                <div className="spaceHeight_50"></div>                    
                    <div className="main_content_heading_1_m">캠브리지 아이엘츠 교재</div>                    
                    <div className="main_content_heading_1_m">최고의</div>                    
                    <div className="main_content_heading_1_m">자습 독학 자료</div>                
                </div>    
                
                <div className="main_content_white_m">                    
                <div className="spaceHeight_50"></div>   
                    <div className="main_content_heading_3_m">GO THRU IELTS 에서는</div>                  
                    <div className="main_content_icon"><Icon name='book' size='huge' color='yellow'/></div>
                    <div className="main_content_heading_4_m">리딩 정답 근거를 지문에서 보여줍니다</div>                           
                    <div className="main_content_heading_5_m"><br></br>답이 왜 이렇게 되었는지</div>   
                    <div className="main_content_heading_5_m">이해가 되지 않을 때가 있나요?</div>                             
                    <div className="main_content_heading_5_m">아카데믹 & 제너널 교재 6권부터 최신 시리즈까지</div> 
                    <div className="main_content_heading_5_m">모두 찾아 놓았습니다</div> 
                    <div className="spaceHeight_30"></div>                               
                    <div className="main_content_icon"><Icon name='language' size='huge' color='yellow'/></div>
                    <div className="main_content_heading_4_m">한국어 해석을 찾고 계셨나요?</div>
                    <div className="main_content_heading_5_m"><br></br>해석이 안 되어 답답하셨나요? </div>
                    <div className="main_content_heading_5_m">리딩 지문 문제, 리스닝 오디오 스크립트</div>
                    <div className="main_content_heading_5_m">한국어 해석이 여기 있습니다</div>    
                    <div className="main_content_heading_5_m">영어 문장 구조 이해를 위해 직역을 우선으로 하여</div>     
                    <div className="main_content_heading_5_m">시험을 위한 직독 직해 능력 향상에 도움이 됩니다</div>            
                    <div className="spaceHeight_30"></div>                               
                    <div className="main_content_icon"><Icon name='question' size='huge' color='yellow'/></div>
                    <div className="main_content_heading_4_m">해석을 봐도 이해가 안 되시나요?</div>
                    <div className="main_content_heading_5_m"><br></br>질문을 올려 주시면 성실히 답변해 드립니다</div>
                    <div className="main_content_heading_4_m"><br></br><br></br>
                        <a href='https://thruielts.blogspot.com/2020/05/go-thru-ielts.html' target='_blank' rel="noopener noreferrer" className='button_yellow'>서비스 미리 보기</a>
                    </div>
                </div>
                <div className="main_content_blue_2_m">
                    <div className="spaceHeight_50"></div> 
                    <div className="main_content_heading_3_w_m">아이엘츠 공부의<br/><br/><br></br> 가장 합리적인 선택 <br/><br/><br></br>지금 바로 시작하세요</div>
                    <div className="main_content_icon"><Icon name='power off' size='huge' color='yellow'/></div>
                    <div className="main_content_heading_4_w_m">무료 회원 가입!</div>   
                    <div className="main_content_heading_4_w_m">이메일만 있으면 가입할 수 있어요!</div>      
                    <div className="main_content_heading_4_w_m"><br></br><br></br>
                        <a href='/SignUp' className='button_yellow'>지금 가입하기</a>
                    </div> 
                </div>
                <div className="main_content_dark_2">
                <div className="spaceHeight_70"></div>  
                    <div className="main_content_heading_3_w_m">안전하고 편리한 결제</div>
                    <div className="main_content_icon"><img src={PaypalLogo} alt="Paypal"/></div>                             
                    <div className="main_content_heading_4_w_m">PayPal 을 결제 수단으로 사용합니다</div>  
                    <div className="spaceHeight_30"></div>                             
                    <div className="main_content_heading_5_w_m">엑티브엑스? 공인인증서? 결제 프로그램? </div>   
                    <div className="main_content_heading_5_w_m">모두 필요없습니다!</div>         
                    <br/><br/> 
                    <div className="main_content_heading_5_w_m">아이폰? 안드로이드? 맥? 윈도우? </div> 
                    <div className="main_content_heading_5_w_m">모두 지원합니다!</div>         
                    <br/><br/> 
                    <div className="main_content_heading_5_w_m">이미 전세계에서 <br></br><br></br> 1억 8천 4백만명 이상이 사용하고 있습니다</div> 
                    <div className="main_content_heading_4"><br></br><br></br>
                        <a href='https://www.paypal.com/kr/webapps/mpp/pay-online?locale.x=ko_KR' target='_blank' rel="noopener noreferrer" className='button_yellow'>Paypal 자세히 알아보기</a>
                    </div> 
                </div>  
                <div className="main_content_dark_footer_m">
                    <div className="main_content_heading_2_s_m">Go Thru IELTS</div>
                </div>                           
            </div>
        );
    }
};

export default MainContent;