import React from 'react';
import EyzyTree from 'eyzy-tree'
import 'eyzy-tree/style.css'
import '../LeftMenu/treeStyle.css'
import { Grid, Loader } from 'semantic-ui-react'
import Logger from '../../Helper/Logger';



export default class Category extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            data:[],
            imageFromServer : '',   
            isDataLoaded: false,
            treeApi:null,
            active:false,
            spinner:true
        };
        this.getMenuTree = this.getMenuTree.bind(this);
        this.onTreeItemClick = this.onTreeItemClick.bind(this);
        this.getDocument = this.getDocument.bind(this);
        this.handleTreeReady = this.handleTreeReady.bind(this);
        this.getParentID = this.getParentID.bind(this);
        this.initTreeApi = this.initTreeApi.bind(this);
        this.findTreeNode = this.findTreeNode.bind(this);
    }

    get displayName() {
        return 'Category';
    }

    async componentDidMount() {        
        if(this.props.onMobile){
            if(this.props.treeData === null || this.props.treeData.length === 0){
                this.initTreeApi();
            } else {
                let treeApi = this.props.treeApi;
                let treeData = this.props.treeData;  
                const selected = treeApi.selected();  
                
                if(selected) {
                    let parents = this.getParentID(selected, 0);
                    var tempArr = parents.split("/");     
        
                    if(tempArr.length > 0) {                
                        for(var i=0; i<tempArr.length; i++){
                            if(tempArr[i] !== "0"){
                                for(var t of treeData) {
                                    this.findTreeNode(t, tempArr[i]);
                                }
                            }                                
                        }
                    }
                }                
    
                this.setState({treeApi:this.props.treeApi, data:treeData, isDataLoaded:true, spinner:false});
            }
        } else {
            this.initTreeApi();
        }        
    } 

    findTreeNode(tree, id) {          
        if (tree.id.toString() === id.trim()) {           
            tree.expanded = true;
        }

        if(tree.child.length > 0) {            
            for (const c of tree.child) {
                const res = this.findTreeNode(c, id);    
                if (res) {
                    return res;
                }
            }
        }
    }

    async initTreeApi(){
        const jsonData = await this.getMenuTree();

        this.setState({data:jsonData, isDataLoaded:true, spinner:false});   
    }    
    
    async componentWillReceiveProps(nextProps){        
        if(nextProps.treeApi){
            this.setState({treeApi:nextProps.treeApi});            
        }
    }    
   
    async getMenuTree(){        
        const url = process.env.REACT_APP_API_URL + "Controllers/Blog/GetCategory.php";
        
        const jsonData = await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json"
            }
        })
        .then((response) => {
            if (response.ok){
                return response.json();                
            } else {
                let s = {
                    message:response.statusText,
                    type:'error',
                    show:true
                }

                this.props.onAlert(s);   
                
                Logger('Blog Category', 'getMenuTree', response.status + response.statusText+response.url , localStorage.getItem("email"));
            }
        })
        .then((data) => {
            return data;
        })
        .catch(err => {
            let s = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }
            this.props.onAlert(s);  

            Logger('Blog Category', 'getMenuTree', 'error message ' + err , localStorage.getItem("email"));

        });
        return jsonData;
    }

    async getDocument(Id){
        if(Id == null || Number(Id) <= 0) return;

        const url = process.env.REACT_APP_API_URL + "Controllers/Blog/GetContent.php";

        const s = {
            Id:Id,
            email:localStorage.getItem("email")
        };
        
        const jsonData = await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body:JSON.stringify(s)
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                let s = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                }
    
                this.props.onAlert(s);  
                Logger('Blog Category', 'getDocument', response.status + response.statusText + response.url , localStorage.getItem("email"));
            }
        })
        .then((data) => {
            return data;
        })
        .catch(err => {
            let s = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }

            this.props.onAlert(s);  

            Logger('Blog Category', 'getDocument', 'error message ' + err , localStorage.getItem("email"));
        });        
           
        return jsonData;
    }

    handleTreeReady(api){
        this.setState({treeApi:api});
        if(this.props.onMobile){
            this.props.onReady(api, this.state.data);  
        }
    }

    async onTreeItemClick(node){    
        if(node === null) return;
        
        const {treeApi} = this.state;
        const selected = treeApi.selected();
        
        if(selected && selected.child.length === 0){
            this.props.onStart();            
            //let parents = this.getParentID(selected, 0)
            
            const doc = await this.getDocument(selected.id);
            
            if(doc !== null){
                this.props.onItemClick(doc);
            } 
        }
    }

    getParentID(node, p){        
        if(node.depth && node.parent){
            if(node.depth > 0){
                return p.toString() + "/" + this.getParentID(node.parent, node.id);
            }
        } else {
            return p.toString() + "/" + node.id.toString();
        }      
    }

    isIterable(obj){
        return Symbol.iterator in Object(obj);
    }

    getNewLine(str)
    {        
        return str.split('\n').map((item, idx) => (
             <span key={idx}>{item}<br></br></span>
            )
        );
    }
    
    render(){
        let tree;
        const {data, spinner} = this.state;
        
        if(this.state.isDataLoaded){
            tree = <EyzyTree data={data} 
                            textRenderer={(props) => {
                                    return <div>{this.getNewLine(props.node.text)}</div>
                                }
                            }
                            expandOnSelect={true} 
                            onSelect={this.onTreeItemClick} 
                            onReady={this.handleTreeReady}/>
            
        } else {
            tree = <Loader active={spinner}  size='massive'/>
        } 
        
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        {tree}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        {this.state.message}
                    </Grid.Column>
                </Grid.Row>            
            </Grid>
        );
    }
};

