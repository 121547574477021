import React from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Form, Grid, Header, Message, Segment, Loader } from 'semantic-ui-react'
import {EmailValidation, PasswordValidation} from '../TextboxValidator/EmailValidation';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from '../../Components/Message/Message';
import Modal from 'react-bootstrap/Modal';
import Logger from '../../Helper/Logger';
//import { loadReCaptcha } from 'react-recaptcha-v3'
//import { ReCaptcha } from 'react-recaptcha-v3'

class SignUp extends React.Component{
    constructor(props) {
        super(props);
       
        this.state = {
            email:'',
            password:'',
            emailValid:true,
            passwordValid:true,
            messageType:'info',
            messageShow:false,
            message:'',
            successShow:false,
            buttonDisable:true,
            spinner:false,
            spinner2:false,
            captcha:'',
        };
        
        this.handleChanged = this.handleChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);        
        this.toggleMessage = this.toggleMessage.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.goToMain = this.goToMain.bind(this);
        this.buttonToggle = this.buttonToggle.bind(this);
        this.captchaValidation = this.captchaValidation.bind(this);
    }

    get displayName() {
        return 'SignUp';
    }

    componentDidMount() {
        //this.setState({spinner2:true});
        //loadReCaptcha('6LcuTtEUAAAAAHWpTXCILBHIuCUwDDfRvY1CuG8s');
    }

    async captchaValidation(recaptchaToken)
    {
        //let { captcha } = this.state;

        /*
        const url = process.env.REACT_APP_API_URL + "api/Users/IsReCaptchaValid";
        const b ={
            ResponseToken:recaptchaToken,
            IsSuccess:false,
            GoToMain:false
        }

        const captchaResponse = await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json"
            },
            body:JSON.stringify(b)
        })
        .then((response) => {
            
            //console.log("response captchaValidation ");
            //console.log(response);
            if (response.ok) {
                return response.json();                
            } else {
                captcha = '';
                //console.log("error " + response.status + ": " + response.statusText);
                let s = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                }
    
                this.toggleMessage(s);  

                Logger('SignUp', 'captchaValidation', response , '');
            }
        })
        .then((data) => {
            return data;    
        })
        .catch(err => {
            captcha = '';
            //console.log(err);
            let s = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }

            this.toggleMessage(s); 

            Logger('SignUp', 'captchaValidation', err , '');
        });

        captcha = captchaResponse.IsSuccess;
*/
        //captcha = true;
        //this.setState({spinner2:false});
        
        //this.setState({captcha}, () => {console.log(this.state.captcha)});
    }

    handleChanged(event)
    {
        this.setState({
            [event.target.id]: event.target.value
        });

        if(event.target.id === 'email')
        {
            this.setState({emailValid:EmailValidation(event.target.value)}, this.buttonToggle)
        } 

        if(event.target.id === 'password')
        {
            this.setState({passwordValid:PasswordValidation(event.target.value)}, this.buttonToggle)
        }
    }

    buttonToggle()
    {
        //const {emailValid, passwordValid, email, password, captcha} = this.state;
        const {emailValid, passwordValid, email, password} = this.state;

        //if(emailValid && passwordValid && email.length > 0 && password.length > 0 && captcha)
        //if(emailValid && passwordValid && email.length > 0 && password.length > 0 )
        if(emailValid && passwordValid && email.length > 0 && password.length > 0)
        {
            this.setState({buttonDisable:false});
        }
        else{

            this.setState({buttonDisable:true});
        }
    }

    toggleMessage(mes)
    {
        let {messageType, messageShow, message} = this.state;

        messageType = mes.type;
        messageShow = mes.show;
        message = mes.message;
        
        this.setState({
            message:message,
            messageShow:messageShow,
            messageType:messageType, 
            spinner:false
        });
    }

    handleClose()
    {
        this.setState({
            messageShow:false
        });
    }

    goToMain()
    {
        //redirect to login page
        this.setState({GoToMain:true});
    }

    async handleSubmit(event)
    {
        event.preventDefault();
        this.setState({
            buttonDisable:true,
            spinner:true
        });

        const url = process.env.REACT_APP_API_URL + "Controllers/User/Register.php";
        const s = {
            email: this.state.email,
            password: this.state.password,
            token:'',
            Description:'',
            isSuccess:false
        };

        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json"
            },
            body:JSON.stringify(s)
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                let s = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                }        
                this.toggleMessage(s); 

                Logger('SignUp', 'handleSubmit', response.status + response.statusText + response.url , localStorage.getItem("email"));
            }
        })
        .then((data) => {
            if(data.isSuccess)
            {
                this.props.statusChanged(true);

                //cookies.set('token', data.token);
                localStorage.setItem('email', data.Email);
                localStorage.setItem('token', data.Token);
                
                this.setState({
                    message:"Cograturations!",
                    successShow:true
                });                   
            }
            else
            {
                let result = "";
                if(data.Description)
                {
                    data.Description.forEach(d => {
                        result = result + d;
                    });
                }

                let s = {
                    message:result,
                    type:'error',
                    show:true
                }        
                this.toggleMessage(s); 
            }
        })
        .catch(err => {
            let s = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }    
            this.toggleMessage(s); 
            Logger('SignUp', 'handleSubmit', 'error message ' + err , localStorage.getItem("email"));
        });

    }

    render() {
        //let {message, messageType, messageShow, successShow, GoToMain, buttonDisable, spinner, spinner2} = this.state;
        let {message, messageType, messageShow, successShow, GoToMain, buttonDisable, spinner} = this.state;
        let redirectComponent;
        
        if(GoToMain)
        {
            redirectComponent = <Redirect to='/' />;
        }
        else
        {
            redirectComponent = <div></div>;
        }
        
        return (
            <div>
                {redirectComponent}    
                <br/><br/><br/> 
                {
                    /*
                        <Dimmer active={spinner2} inverted><Loader active={spinner2}  size='massive'>Please wait...We are checking robots</Loader></Dimmer>
                    */
                }
                
                <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='top'>
                    <Grid.Row>
                        <Grid.Column style={{ maxWidth: 500 }}>
                        <Header as='h2' color='teal' textAlign='center'>
                            유효한 이메일이 필요합니다. <br/><br/>비밀번호 분실시 
                            <br></br>가입된 이메일이 이용됩니다.<br/><br/>
                        </Header> 
                        <Form size='large' onSubmit={this.handleSubmit}>                
                            <Segment stacked>
                            <Form.Input 
                                    fluid 
                                    icon='user' 
                                    iconPosition='left' 
                                    placeholder='Email address' 
                                    value={this.state.email} 
                                    onChange={this.handleChanged} 
                                    id="email" 
                                    name="email"/>
                            <Form.Input
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Password'
                                type='password'
                                id="password" 
                                name="password" 
                                onChange={this.handleChanged}
                                value={this.state.password}
                            />
                            <Message>
                                회원 가입을 함으로써 서비스 약관에 동의합니다.
                            </Message>

                            {!this.state.emailValid &&
                                <Message  color='red'>
                                    이메일 주소가 부정확합니다. 다시 확인해 주십시요.
                                </Message>
                            }
                            {!this.state.passwordValid &&
                                <Message  color='red'>
                                    비밀번호는 6자 이상이어야 하며, 최소한 1자 씩의 대문자, 숫자, 특수문자를 포함해야합니다.
                                </Message>
                            }
                            <Loader active={spinner} />
                            <Button color='teal' fluid size='large' disabled={buttonDisable}>회원가입</Button>                              
                            </Segment>
                            </Form>                           
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {
                    /*
                    <ReCaptcha
                        sitekey="6LcuTtEUAAAAAHWpTXCILBHIuCUwDDfRvY1CuG8s"
                        action='login'
                        verifyCallback={this.captchaValidation}
                    />
                    */
                }                
                
                <Modal show={successShow} onHide={this.goToMain} aria-labelledby="contained-modal-title-vcenter">  
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Successful!
                        </Modal.Title>
                    </Modal.Header>                          
                    <Modal.Body>
                    
                    <Message info>
                        <Message.Header>{message}</Message.Header>
                    </Message>   
                    <Button primary onClick={this.goToMain}>
                    Go to Main page
                    </Button>   
                    </Modal.Body>
                </Modal>
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={messageShow}
                    autoHideDuration={6000}
                    onClose={this.handleClose}>
                        <SnackbarContentWrapper
                                onClose={this.handleClose}
                                variant={messageType}
                                message={message}
                        />
                </Snackbar>  
            </div>
        );
    }
};

export default SignUp;