import React from 'react';
// import Comments from './Comment';
import BlogMain from "./BlogMain";
import './BlogContent.css';
import ReactHtmlParser from 'react-html-parser';
import Logger from '../../Helper/Logger';
import { Loader } from 'semantic-ui-react'
import { Button} from 'semantic-ui-react'
import ModalLoginForm from '../Login/ModalLoginForm';
import { Link, withRouter } from 'react-router-dom';

class Content extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            docTitle:'Cambridge IELTS',
            docContentId:0,
            isLoggedIn: false,
            content:null,
            spinner: false,
            showLogInForm:false            
        };
        
        this.loginChanged = this.loginChanged.bind(this);
        this.updateDocumentByState = this.updateDocumentByState.bind(this);   
        this.getContent = this.getContent.bind(this);
        this.showLoginMsg = this.showLoginMsg.bind(this);
        this.showLoginModal = this.showLoginModal.bind(this);
        this.setLoginState = this.setLoginState.bind(this);
    }

    get displayName() {
        return 'Blog Content';
    }

    showLoginModal(){
        this.setState({showLogInForm:true});
    }

    componentDidMount(){
        this.setState({isLoggedIn:this.props.isLoggedIn});
    }      
    
    componentWillReceiveProps(nextProps) {        
        this.setState({
            isLoggedIn:nextProps.isLoggedIn            
        });

        if(nextProps.data !== null && nextProps.data.ContentId > 0){
            if(this.state.docContentId === null 
                || (this.state.docContentId !== null && Number(this.state.docContentId) !== Number(nextProps.data.ContentId))){
                    this.getContent(nextProps.data.ContentId);
                }
        } else {
            this.getContent(0);
        }  
    }
    
    async getContent(ContentId) {   
        if(ContentId == null || Number(ContentId) <= 0){
            this.setState({content:null, docContentId:0});
            this.props.isLoaded(true);
            return;
        } 

        this.setState({spinner:true});

        //const url = process.env.REACT_APP_BLOG_API_URL + "wp-json/wp/v2/posts/" + ContentId;    
        const url = process.env.REACT_APP_API_URL + "Controllers/Blog/GetBlogContent.php"; 
               
        const s = {
            cid:ContentId
        };
            
        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json",
                Accept: 'application/json'
            },
            body:JSON.stringify(s)
        })
        .then((response) => {                    /* eslint-disable-line */
            if (response.ok) {
                return response.json();                    
            } else {
                Logger('Blog Content', 'getContent', response.status + response.statusText + response.url , localStorage.getItem("email"));
            }
        })
        .then((data) => {
            this.setState({content:data.content, docContentId:data.id, docTitle:data.title, spinner:false});            
        })
        .catch(err => {  /* eslint-disable-line */    
            Logger('Blog Content', 'getContent', 'error message ' + err , localStorage.getItem("email"));
        }); 
        
        this.props.isLoaded(true);
    }

    setLoginState(){
        this.loginChanged(true);
    }

    async loginChanged(status){
        this.setState({isLoggedIn:status, showLogInForm:false}, await this.updateDocumentByState(status));
    }

    async updateDocumentByState(status) {
        this.props.statusChanged(status);
    }

    showLoginMsg(content){
        let maxLen = content.length / 3
        maxLen = ~~maxLen;

        let rtn = <div>
                    <div>{ReactHtmlParser(content.substring(1, maxLen)  + " ...")}</div>
                    <div>
                        <p><br></br><br></br>
                        You can see full contents when you &nbsp;&nbsp;
                        <Button basic color='blue' content='Log In' onClick={this.showLoginModal} />
                        </p>
                        <p>
                            Don't have an account? You can <Link to="/SignUp"><b><u>Sign Up</u></b></Link> now!
                        </p>
                    </div>
                </div>

        return rtn;
    }

    render(){
        const {docContentId, docTitle, content, spinner, isLoggedIn, showLogInForm} = this.state;
        let rtn ;

        if(docContentId > 0 && content && !spinner)
        {               
            rtn = <div>
                        <table cellPadding="5" cellSpacing="5" >
                            <thead>
                                <tr>
                                    <th><div className="document_title">{docTitle.rendered}</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='noselect'>{(isLoggedIn) ? ReactHtmlParser(content.rendered) : this.showLoginMsg(content.rendered) }</div>                            
                                    </td>
                                </tr>   
                            </tbody>
                        </table>                       
                    </div>
        }
        else if(docContentId === 0 && content == null && !spinner){
            rtn = <BlogMain />
        }
        else {
            rtn = <Loader active={spinner}  size='massive'/>
        }

        return (
            <div>
                {rtn}   
                <ModalLoginForm onCompleted={this.setLoginState} enable={showLogInForm} />             
            </div>
        );
    }
};

export default withRouter(Content);