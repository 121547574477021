import React from 'react';
import LoginForm from '../../Components/Login/LoginForm';

class AuthenticationContainer extends React.Component{
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: false
        };
        
        this.loginChanged = this.loginChanged.bind(this);
    }

    componentDidMount() 
    {
        this._isMounted = true;
    }

    loginChanged(status)
    {
        if(this._isMounted) this.setState({isLoggedIn:status}, this.props.statusChanged(status));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render(){
        let {isLoggedIn} = this.state;
        return (  
            <div>
            <LoginForm isLoggedIn={isLoggedIn} statusChanged={this.loginChanged}/>
            </div>
        );
    }
};

export default AuthenticationContainer;