import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Form, Grid, Header, Message, Segment, Loader } from 'semantic-ui-react'
import Modal from 'react-bootstrap/Modal';
import Logger from '../../Helper/Logger';

class ModalLoginForm extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            email:'',
            password:'',
            buttonDisable:true,
            spinner:false,
            showModal:false,
            message:'',
            messageInfo:false,
            messageError:false
        };
        
        this.handleChanged = this.handleChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.buttonToggle = this.buttonToggle.bind(this);     
        this.handleClose = this.handleClose.bind(this);   
        this.resetForm = this.resetForm.bind(this);
    }

    componentWillReceiveProps(nextProps)
    {
        this.setState({showModal:nextProps.enable});
    }

    get displayName() {
        return 'LoginForm';
    }
    
    handleChanged(event)
    {
        let {message} = this.state;

        if(message)
        {
            this.setState({
                message:'',
                messageInfo:false,
                messageError:false
            });
        }

        this.setState({
            [event.target.name]: event.target.value
        }, this.buttonToggle());        
    }

    buttonToggle()
    {
        const {email, password} = this.state;

        if(email && password)
        {
            this.setState({buttonDisable:false});
        }
        else
        {
            this.setState({buttonDisable:true});
        }
    }

    resetForm()
    {
        this.setState({
            buttonDisable:true,
            spinner:false,
            email:'',
            password:''
        });
    }    

    handleClose()
    {
        this.setState({
            showModal:false
        });
    }

    async handleSubmit(event)
    {
        event.preventDefault();

        this.setState({
            buttonDisable:true,
            spinner:true
        });
            
        const url = process.env.REACT_APP_API_URL + "Controllers/User/Authenticate.php";
        const s = {
            email: this.state.email,
            password: this.state.password,
            token:'',
            Description:'',
            isSuccess:false
        };

        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json"
            },
            body:JSON.stringify(s)
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                Logger('ModalLoginForm', 'handleSubmit', response.status + response.statusText + response.url , localStorage.getItem("email"));
            }
        })
        .then((data) => {
            if(data.isSuccess)
            {
                this.setState({
                    messageInfo:true,
                    messageError:false,
                    message:"Cograturations!"
                }); 

                localStorage.clear();

                localStorage.setItem('email', data.Email);
                localStorage.setItem('token', data.Token);

                this.props.onCompleted(true);
            }
            else
            {
                this.resetForm();
                let result = "";
                if(data.Description)
                {
                    data.Description.forEach(d => {
                        result = result + d;
                    });
                }
                this.setState({
                    message:result,
                    messageInfo:false,
                    messageError:true
                });                
            }
        })
        .catch(err => {
            Logger('ModalLoginForm', 'handleSubmit', 'error message ' + err , localStorage.getItem("email"));
        });
    }

    render(){
        let {message, showModal, buttonDisable, spinner, messageInfo, messageError} = this.state;
        
        return (   
            <div>    
                <Modal show={showModal} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter">  
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Please Login here
                        </Modal.Title>
                    </Modal.Header>                          
                    <Modal.Body>
                        <Grid textAlign='center' verticalAlign='top'>
                            <Grid.Column style={{ maxWidth: 550 }}>
                            <Header as='h2' color='blue' textAlign='center'>
                                Log in 
                            </Header>
                            <Form size='large' onSubmit={this.handleSubmit}>
                                <Segment stacked>
                                <Form.Input fluid 
                                            icon='user' 
                                            iconPosition='left' 
                                            placeholder='Email address' 
                                            id="email" 
                                            name="email" 
                                            onChange={this.handleChanged}
                                            value={this.state.email}
                                />
                                <Form.Input
                                    fluid
                                    icon='lock'
                                    iconPosition='left'
                                    placeholder='Password'
                                    type='password'
                                    id="password" 
                                    name="password" 
                                    onChange={this.handleChanged}
                                    value={this.state.password}
                                />                                
                                <Loader active={spinner} />
                                <Button color='blue' fluid size='large' disabled={buttonDisable}>Log In</Button>           
                                </Segment>
                            </Form>                    
                            </Grid.Column>
                        </Grid>
                    <Message info={messageInfo} error={messageError}>
                        <Message.Header>{message}</Message.Header>
                    </Message>  
                    </Modal.Body>
                </Modal>                
            </div>
        );
    }
};

export default withRouter(ModalLoginForm);