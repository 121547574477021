import React from 'react';
import { withRouter } from 'react-router-dom';
import { Icon, Form, Grid, Message, Loader,Dimmer, Popup } from 'semantic-ui-react'
import Modal from 'react-bootstrap/Modal';
import Logger from '../../Helper/Logger';

/*
const seriesOptions = [
    { key: '11', text: 'Cambridge IELTS 11', value: 'Cambridge IELTS 11' },
    { key: '12', text: 'Cambridge IELTS 12', value: 'Cambridge IELTS 12' },
    { key: '13', text: 'Cambridge IELTS 13', value: 'Cambridge IELTS 13' },
    { key: '14', text: 'Cambridge IELTS 14', value: 'Cambridge IELTS 14' }
]

const moduleOptions = [
    { key: 'AC', text: 'Academic', value: 'Academic' },
    { key: 'GT', text: 'General Training', value: 'General Training' }
]
*/

class QuestionFormModal extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            email:'',
            password:'',
            buttonDisable:true,
            spinner:false,
            showModal:false,
            message:'',
            messageInfo:false,
            messageError:false,
            messageType:'info',
            messageShow:false,
            open:0,
            series:'',
            module:'',
            question:''
        };
        
        this.handleChanged = this.handleChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);  
        this.handleClose = this.handleClose.bind(this);   
        this.resetQuestionForm = this.resetQuestionForm.bind(this);
        this.toggleMessage = this.toggleMessage.bind(this);
        this.buttonToggle = this.buttonToggle.bind(this);
    }

    componentWillReceiveProps(nextProps)
    {
        this.setState({showModal:nextProps.enable});
    }

    get displayName() {
        return 'LoginForm';
    }
    
    handleChanged(event)
    {
        if(event.target.id === 'open' && event.target.checked)
        {
            this.setState({
                open: 1
            });
        }
        else if (event.target.id === 'open' && !event.target.checked)
        {
            this.setState({
                open: 0
            });
        }
        else{
            this.setState({
                [event.target.id]: event.target.value
            }, this.buttonToggle());
        }
        
    }

    buttonToggle()
    {
        const {question, series} = this.state;

        if(question && series)
        {
            this.setState({buttonDisable:false});
        }
        else
        {
            this.setState({buttonDisable:true});
        }
    }

    resetQuestionForm()
    {
        this.setState({
            series:'',
            module:'',
            open:'',
            question:'',
            spinner:false,
            buttonDisable:true
        })
    } 
    
    toggleMessage(mes)
    {
        let {messageType, messageShow, message} = this.state;

        messageType = mes.type;
        messageShow = mes.show;
        message = mes.message;
        
        this.setState({
            message:message,
            messageShow:messageShow,
            messageType:messageType, 
            spinner:false
        });
    }

    handleClose()
    {
        this.setState({
            showModal:false
        });

        this.props.onClosed(false);
    }

    async handleSubmit(event)
    {
        event.preventDefault();
        
        const {open, series, module, question} = this.state;
        let msg;
        
        this.setState({
            buttonDisable:true,
            spinner:true
        });
           
        const url = process.env.REACT_APP_API_URL + "Controllers/Question/SubmitQuestion.php";
        const s = {
            Series: series,
            Module: module,
            Open:open,
            Content:question,
            email:localStorage.getItem("email")
        };

        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body:JSON.stringify(s)
        })
        .then((response) => {   
            
            if (response.ok) { 
                msg = {
                    message:"Your request has been successfully completed!",
                    type:'success',
                    show:true
                }        
                //this.toggleMessage(s); 
                this.resetQuestionForm();
                this.props.onCompleted(true);
                //this.handleClose();
            } else {
                this.resetQuestionForm();
                msg = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                }        
                //this.toggleMessage(s); 
            }
        })
        .catch(err => {
            msg = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }    
            //this.toggleMessage(s); 
            Logger('QuestionModal', 'handleSubmit', 'error message ' + err , localStorage.getItem("email"));
        });

        this.props.onCompleted(msg);    
    }

    render(){
        let {message, showModal, buttonDisable, spinner, messageInfo, messageError} = this.state;
        
        return (   
            <div> 
                <Dimmer active={spinner} inverted><Loader active={spinner}  size='massive'>Loading</Loader></Dimmer>   
                <Modal show={showModal} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter">  
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            질문을 작성해 주세요. 
                        </Modal.Title>
                    </Modal.Header>                          
                    <Modal.Body>
                        <Grid textAlign='center' verticalAlign='top'>
                            <Grid.Column style={{ maxWidth: 550 }}>
                                <Form size='large' onSubmit={this.handleSubmit}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <select id="series" onChange={this.handleChanged} value={this.state.series}>
                                                        <option value="0">Select Series</option>
                                                        <option value="11">Cambridge IELTS 11</option>
                                                        <option value="12">Cambridge IELTS 12</option>
                                                        <option value="13">Cambridge IELTS 13</option>
                                                        <option value="14">Cambridge IELTS 14</option>
                                                        <option value="15">Cambridge IELTS 15</option>
                                                        <option value="16">Cambridge IELTS 16</option>
                                                        <option value="16">Cambridge IELTS 17</option>
                                                        <option value="16">Cambridge IELTS 18</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select id="module" onChange={this.handleChanged} value={this.state.module}>
                                                        <option value="0">Select Module</option>
                                                        <option value="AC">Academic</option>
                                                        <option value="GT">General Training</option>
                                                    </select>
                                                </td>
                                                <td>
                                                <input
                                                    id="open"
                                                    value={this.state.open}
                                                    name="open"
                                                    type="checkbox"
                                                    onChange={this.handleChanged}
                                                    />공개
                                                    <Popup
                                                        trigger={
                                                            <Icon name='question circle outline' color='grey'/>        
                                                        }
                                                        content='공개를 선택하시면 다른 회원도 열람이 가능합니다'
                                                    >
                                                    </Popup>   
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>                        
                                    <Form.TextArea label='' 
                                                placeholder='Tell us more about your question...' 
                                                id="question" 
                                                name="question" 
                                                onChange={this.handleChanged}
                                                value={this.state.question}
                                    />                            
                                    <br></br>
                                    <Form.Button color="yellow" size='large' disabled={buttonDisable}><font color="#000000">질문 등록하기</font></Form.Button>
                                    <br></br><br></br>
                                </Form>                    
                            </Grid.Column>
                        </Grid>
                    <Message info={messageInfo} error={messageError}>
                        <Message.Header>{message}</Message.Header>
                    </Message>  
                    </Modal.Body>
                </Modal>                
            </div>
        );
    }
};

export default withRouter(QuestionFormModal);