async function Logger (component, func, errMessage, userEmail)
{
    if(userEmail === null)
    {
        userEmail = "not logged in user";
    }

    const url = process.env.REACT_APP_API_URL + "Controllers/Logger/LogClientError.php";
    const s = {
        ComponentName: component,
        FunctionName: func, 
        ErrorMessage: errMessage,
        UserEmail: userEmail
    };

    const response = await fetch(url, {
        method:"POST",
        mode: 'cors',
        headers:{
            "Content-Type": "application/json",
            'Accept': 'application/json'
        },
        body:JSON.stringify(s)
    });

    if(!response.ok) { console.log("Logging error " + response.status + ", " + response.statusText)};   
}

export default Logger;