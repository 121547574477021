import React from 'react';
import {Responsive } from 'semantic-ui-react'
import './MainContent.css';

class DocumentContent extends React.Component{
        
    render(){
        
        return (
            <div>
            <Responsive {...Responsive.onlyComputer}>
                <div className="main_document_container">
                    <div className="content_title">교재의 시리즈를 선택하세요<br/><br/></div> 
                    <div>  
                        <br></br>                    
                        <p>리딩 지문 속 정답 근거 문장 및 설명</p>
                        <p>리딩 지문 해석 (캠브리지 시리즈 6 ~ 17)</p>
                        <p>리딩 문제 해석 (캠브리지 시리즈 11 ~ 17)</p>
                        <p>리스닝 스크립트 해석 (캠브리지 시리즈 6 ~ 17)</p>
                        <br/><br/>
                    </div>
                </div>
            </Responsive>  
            <Responsive {...Responsive.onlyTablet}>
                <div className="main_document_container">
                    <div className="content_title">교재의 시리즈를 선택하세요<br/><br/></div> 
                    <div>  
                        <br></br>                    
                        <p>리딩 지문 속 정답 근거 문장</p>
                        <p>리딩 지문 해석</p>
                        <p>캠브리지 시리즈 11 ~ 16 리딩 문제 해석</p>
                        <p>리스닝 스크립트 해석</p>
                        <br/><br/>
                    </div>
                </div>
            </Responsive> 
            <Responsive {...Responsive.onlyMobile}>
                <div className="main_document_container_m">
                    <div className="content_title">교재의 시리즈를 선택하세요<br/><br/></div> 
                    <div>  
                        <br></br>                    
                        <p>리딩 지문 속 정답 근거 문장</p>
                        <p>리딩 지문 해석</p>
                        <p>캠브리지 시리즈 11 ~ 16 리딩 문제 해석</p>
                        <p>리스닝 스크립트 해석</p>
                        <br/><br/>
                    </div>
                </div>
            </Responsive> 
            </div>           
        );
    }
};

export default DocumentContent;