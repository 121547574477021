import React from 'react';
import { Segment, Divider, Item } from 'semantic-ui-react'
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from '../../Components/Message/Message';
import Logger from  '../../Helper/Logger';
import { Link } from 'react-router-dom';
import QuestionList from './QuestionList';
import "./Question.css";

class Question extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: false,
            messageType:'info',
            messageShow:false,
            message:'',
            spinner:false,
            isPaidUser: false,
            isAdminUser: false,
            showForm:false,
            loadList:false
        };
        this.statusChange = this.statusChange.bind(this);     
        this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
        this.setLoginState = this.setLoginState.bind(this);
        this.statusChange = this.statusChange.bind(this);
        this.getPaidUser = this.getPaidUser.bind(this); 
        this.getAdminUser = this.getAdminUser.bind(this); 
    }

    componentDidMount() {
        if(!this.state.isLoggedIn && this.props.isLoggedIn)
        {
            this.setState({isLoggedIn:true});
        }
    }

    componentWillReceiveProps(nextProps)
    {
        if(!this.state.isLoggedIn && nextProps.isLoggedIn)
        {
            this.setState({isLoggedIn:true}, this.setLoginState());
        }
    }

    setLoginState(){
        this.setState({
            isLoggedIn:true
        }, this.statusChange(true));
    }

    statusChange(s)
    {
        this.props.statusChanged(s);

        //check paid user
        if(s)
        {
            this.getPaidUser();
            this.getAdminUser();
        }
    }
  
    toggleMessage(mes)
    {
        let {messageType, messageShow, message} = this.state;

        messageType = mes.type;
        messageShow = mes.show;
        message = mes.message;
        
        this.setState({
            message:message,
            messageShow:messageShow,
            messageType:messageType, 
            spinner:false
        });
    }    

    async getPaidUser()
    {
        if(localStorage.getItem("token") === null) return;

        this.setState({
            buttonDisable:true,
            isProcessing:true,
            spinner:true            
        });

        const url = process.env.REACT_APP_API_URL + "Controllers/User/PaidUserCheck.php";
        const s = {
            email: localStorage.getItem("email"),
            token:'',
            Description:'',
            isSuccess:false
        };

        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body:JSON.stringify(s)
        })
        .then((response) => {   
           
            if (response.ok) {
                return response.json();
            } else {   
                this.setState({
                    isPaidUser:false
                });         
                
            }
        })
        .then((data) => {
            
            this.setState({
                spinner:false
            }); 

            if(data !== undefined || data !== 'undefined' || typeof data !== 'undefined' || typeof data !== undefined) {
                if(data && data.isSuccess) {
                    //checkValue = true;  
                   
                    this.setState({
                        isPaidUser:true
                    }); 
                } else {
                    this.setState({
                        isPaidUser:false
                    }); 
                }
            } else {
                this.setState({
                    isPaidUser:false
                });
            } 
        })
        .catch(err => {
            
            Logger('getPaidUser-catch in Question', 'getPaidUser-catch', 'error message ' + err , localStorage.getItem("email"));

            this.setState({
                isPaidUser:false
            });           
        });
    }  
    
    async getAdminUser() {
        this.setState({
            buttonDisable:true,
            isProcessing:true,
            spinner:true            
        });

        const url = process.env.REACT_APP_API_URL + "Controllers/User/AdminUserCheck.php";
        const s = {
            email: localStorage.getItem("email"),
            token:'',
            Description:'',
            isSuccess:false
        };

        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body:JSON.stringify(s)
        })
        .then((response) => {   
            if (response.ok) {
                return response.json();
            } else {   
                this.setState({
                    isAdminUser:false
                });         
                
            }
        })
        .then((data) => {
            this.setState({
                spinner:false
            }); 

            if(data !== undefined || data !== 'undefined' || typeof data !== 'undefined' || typeof data !== undefined) {
                if(data && data.isSuccess) {
                    //checkValue = true;  
                   
                    this.setState({
                        isAdminUser:true
                    }); 
                } else  {
                    this.setState({
                        isAdminUser:false
                    }); 
                }
            } else {
                this.setState({
                    isAdminUser:false
                });
            }   
            
        })
        .catch(err => {
            
            Logger('getAdminUser-catch', 'getAdminUser-catch', 'error message ' + err , localStorage.getItem("email"));

            this.setState({
                isAdminUser:false
            });           
        });
    }  

    handleCloseSnackbar()
    {
        this.setState({
            messageShow:false
        });
    }

    render(){
          
        let {isLoggedIn, isPaidUser, isAdminUser} = this.state;
        let {messageType, messageShow,  message, loadList} = this.state;
        return (   
            <div>                
                <div className="support_content_heading"><br></br>캠브리지 교재 질문 답변</div>
                <div className="support_content_body">유료 회원 전용 교재 질문 답변 게시판입니다.</div>  
                <div className="support_content_body">등록된 질문은 2 영업일 이내 답변해 드립니다.(한국 시간 기준)</div>  
                <div className="support_content_body">캠브리지 교재 이외의 질문은 답변이 제한될 수 있습니다.</div>  <br></br><br></br>
                     
                {(isLoggedIn) ? ( 
                <div>                    
                    <br></br>
                    <div>
                    <QuestionList onLoad={loadList} paidUser={isPaidUser} adminUser={isAdminUser} isMy={false}/>
                    </div>                    
                    <Snackbar
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        }}
                        open={messageShow}
                        autoHideDuration={6000}
                        onClose={this.handleCloseSnackbar}>
                            <SnackbarContentWrapper
                                    onClose={this.handleCloseSnackbar}
                                    variant={messageType}
                                    message={message}
                            />
                    </Snackbar> 
                </div>
                 ):(
                    <div>
                        <br></br>
                    <Segment color='purple' placeholder>                    
                    <Item>
                    <Item.Content>
                        <Item.Header as='a'>
                            <div className="item_title">Log in is necessary for this page</div>
                            <Divider />
                        </Item.Header>
                        <Item.Description>
                    <div className="content_body">
                        <br></br><br></br>
                        <p>Please <b><u><Link to="/Authentication">Log In here</Link></u></b></p>
                        <p>New to us? Why don't you <Link to="/SignUp"><b><u>Sign Up</u></b></Link> now?</p>
                    </div>
                    </Item.Description>
                    </Item.Content>
                    </Item>
                    </Segment> 
                    </div>
                )} 
            </div>
        );
    }
};

export default Question;