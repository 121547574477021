import React from 'react';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Button, Form, Grid, Header, Message, Segment, Loader } from 'semantic-ui-react'
import {EmailValidation, PasswordValidation} from '../TextboxValidator/EmailValidation';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from '../../Components/Message/Message';
import Logger from '../../Helper/Logger';

class LoginForm extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            email:'',
            password:'',
            result:'',
            GoToMain:false,
            emailValid:true,
            passwordValid:true,
            messageType:'success',
            messageShow:false,
            message:'',
            buttonDisable:true,
            spinner:false
        };
        
        this.handleChanged = this.handleChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.buttonToggle = this.buttonToggle.bind(this);
        this.toggleMessage = this.toggleMessage.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.resetLoginForm = this.resetLoginForm.bind(this);
    }

    get displayName() {
        return 'LoginForm';
    }   

    handleChanged(event)
    {
        this.setState({
            [event.target.id]: event.target.value
        });

        if(event.target.id === 'email')
        {
            this.setState({emailValid:EmailValidation(event.target.value)}, this.buttonToggle)
        } 

        if(event.target.id === 'password')
        {
            this.setState({passwordValid:PasswordValidation(event.target.value)}, this.buttonToggle)
        }  
    }

    buttonToggle()
    {
        const {emailValid, passwordValid, email, password} = this.state;

        if(emailValid && passwordValid && email.length > 0 && password.length > 0)
        {
            this.setState({buttonDisable:false});
        }
        else{

            this.setState({buttonDisable:true});
        }
    }

    toggleMessage(mes)
    {
        let {messageType, messageShow, message} = this.state;

        messageType = mes.type;
        messageShow = mes.show;
        message = mes.message;
        
        this.setState({
            message:message,
            messageShow:messageShow,
            messageType:messageType, 
            spinner:false
        });
    }

    handleClose()
    {
        this.setState({
            messageShow:false
        });
    }

    resetLoginForm()
    {
        this.setState({
            email:'',
            password:'',
            buttonDisable:true
        })
    }

    async handleSubmit(event)
    {
        event.preventDefault();

        this.setState({
            buttonDisable:true,
            spinner:true
        });

        let resultMessage;
        
        const url = process.env.REACT_APP_API_URL + "Controllers/User/Authenticate.php";
        const s = {
            email: this.state.email,
            password: this.state.password,
            token:'',
            Description:'',
            isSuccess:false
        };

        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json"
            },
            body:JSON.stringify(s)
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                resultMessage = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                } 

                this.toggleMessage(resultMessage); 
                this.resetLoginForm();

                Logger('LoginForm', 'handleSubmit', response.status + response.statusText + response.url , localStorage.getItem("email") + "|" + this.state.email);
            }
        })
        .then((data) => {
            if(data.isSuccess)
            {
                this.props.statusChanged(true);

                localStorage.clear();

                //cookies.set('token', data.token);
                localStorage.setItem('email', data.Email);
                localStorage.setItem('token', data.Token);
                
                //redirect to main document content
                this.setState({GoToMain:true});
            }
            else
            {
                let result = "";
                if(data.Description)
                {
                    data.Description.forEach(d => {
                        result = result + d;
                    });
                }

                resultMessage = {
                    message:result,
                    type:'error',
                    show:true
                } 

                this.toggleMessage(resultMessage); 
                this.resetLoginForm();
            }
        })
        .catch(err => {
            resultMessage = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }

            this.toggleMessage(resultMessage); 

            Logger('LoginForm', 'handleSubmit', 'error message ' + err , localStorage.getItem("email"));
            this.resetLoginForm();
        });        
    }

    render(){
        let {message, messageType, messageShow, GoToMain, buttonDisable, spinner} = this.state;
        let redirectComponent;        
        
        if(GoToMain)
        {
            redirectComponent = <Redirect to='/' />;
        }
        else
        {
            redirectComponent = <div></div>;
        }

        return (   
            <div>                 
                {redirectComponent}  
                <br></br><br></br>    
                <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='top'>
                    <Grid.Column style={{ maxWidth: 550 }}>
                    <Header as='h2' color='teal' textAlign='center'>
                        Log in 
                    </Header>
                    <Form size='large' onSubmit={this.handleSubmit}>
                        <Segment stacked>
                        <Form.Input fluid 
                                    icon='user' 
                                    iconPosition='left' 
                                    placeholder='Email address' 
                                    id="email" 
                                    name="email" 
                                    onChange={this.handleChanged}
                                    value={this.state.email}
                        />
                        <Form.Input
                            fluid
                            icon='lock'
                            iconPosition='left'
                            placeholder='Password'
                            type='password'
                            id="password" 
                            name="password" 
                            onChange={this.handleChanged}
                            value={this.state.password}
                        />
                        
                        {!this.state.emailValid &&
                            <Message color='red'>
                                Email address is not valid. Please check this out again.
                            </Message>
                        }
                        {!this.state.passwordValid &&
                            <Message color='red'>
                                Password should have at least 1 upper case character, 1 digit and 1 non alphanumeric character
                            </Message>
                        }
                        <Loader active={spinner} />
                        <Button color='teal' fluid size='large' disabled={buttonDisable}>Log In</Button>           
                        </Segment>
                    </Form>
                    <Message>
                        <p>New to us? Why don't you <Link to="/SignUp"><b><u>Sign Up</u></b></Link> now?</p>
                        <p>Forgot password? <Link to="/PasswordReset"><b><u>Reset Password!</u></b></Link></p><br></br>
                        <p>Not received Activation mail? <br></br>Have you checked Spam Mail box? <br></br>
                        Don't worry! You can request <Link to="/RequestEmailLink"><b><u>Send link again!</u></b></Link></p> 
                    </Message>                    
                    </Grid.Column>
                </Grid>    
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={messageShow}
                    autoHideDuration={6000}
                    onClose={this.handleClose}>
                        <SnackbarContentWrapper
                                onClose={this.handleClose}
                                variant={messageType}
                                message={message}
                        />
                </Snackbar>   
                
            </div>
        );
    }
};

export default withRouter(LoginForm);