import React from 'react';
import DocumentContainer from "../DocumentContainer/DocumentContainer";
import AuthenticationContainer from "../AuthenticationContainer/AuthenticationContainer";
import ContentContainer from "../ContentContainer/ContentContainer";
import SighUpForm from "../../Components/SignUpForm/SignUp";
import RequestEmailConfirmForm from '../../Components/Login/RequestEmailConfirmForm';
import MyPageContainer from "../../Containers/MyPageContainer/MyPageContainer";
import BuyNowContainer from '../BuyNowContainer/BuyNowContainer';
import Logout from "../../Components/Login/Logout";
import PasswordResetRequestForm from "../../Components/Login/PasswordResetRequestForm";
import ContactUs from '../../Components/MainContent/ContactUs';
import TnC from '../../Components/MainContent/TnC';
import './MainContainer.css';
import {Route,Switch,withRouter} from 'react-router-dom';
import ConfirmEmail from '../../Components/SignUpForm/ConfirmEmail';
import TopMenu from '../../Components/TopMenu/TopMenu';
import LoginCheck from '../../Helper/LoginCheck';
import Logger from '../../Helper/Logger';
import PasswordResetForm from '../../Components/Login/PasswordResetForm';
//import EventContainer from '../ContentContainer/EventContainer';
import NotFound from '../../Helper/NotFound';
import Question from '../../Components/Question/Question';

import BlogContainer from "../../Containers/BlogContainer/BlogContainer";

class MainContainer extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: false
        }

        this.loginChanged = this.loginChanged.bind(this);       
    }

    async checkLogin()
    {
        let check = false;
        try
        {               
            check = await LoginCheck();

            if(check)
            {
                this.setState({isLoggedIn:true});
            }
        } 
        catch(error)
        {
            Logger('MainContainer', 'checkLogin', 'error message ' + error , localStorage.getItem("email"));
        }
    }

    loginChanged(status)
    {
        this.setState({isLoggedIn:status});
    }

    async componentDidMount()
    {        
        if(!this.state.isLoggedIn)
        {
            await this.checkLogin();
        }
    }

    async componentWillReceiveProps(nextProps)
    {
        if(!this.state.isLoggedIn)
        {
            await this.checkLogin();
        }
    }
 
    render(){       
        const {isLoggedIn} = this.state;
        return (
            <div className="main_container">
                <TopMenu isLoggedIn={isLoggedIn} />
                <Switch> 
                    <Route exact path="/" render={(props) => <ContentContainer {...props} statusChanged={this.loginChanged} isLoggedIn={isLoggedIn} /> } />
                    <Route path="/Document" render={(props) => <DocumentContainer {...props} statusChanged={this.loginChanged} isLoggedIn={isLoggedIn} /> } />
                    <Route path="/Authentication" render={(props) => <AuthenticationContainer {...props} statusChanged={this.loginChanged} isLoggedIn={isLoggedIn} /> } />
                    <Route path="/SignUp" render={(props) => <SighUpForm {...props} statusChanged={this.loginChanged} isLoggedIn={isLoggedIn} /> } />
                    <Route path="/MyPage" render={(props) => <MyPageContainer {...props} statusChanged={this.loginChanged} isLoggedIn={isLoggedIn} /> } />
                    <Route path="/Logout" render={(props) => <Logout {...props} statusChanged={this.loginChanged} isLoggedIn={isLoggedIn} /> } />
                    <Route path="/BuyNow" render={(props) => <BuyNowContainer {...props} statusChanged={this.loginChanged} isLoggedIn={isLoggedIn} /> } />
                    <Route path="/PasswordReset" render={(props) => <PasswordResetRequestForm {...props} statusChanged={this.loginChanged} isLoggedIn={isLoggedIn} /> } />     
                    <Route path="/Support" render={(props) => <ContactUs {...props} statusChanged={this.loginChanged} isLoggedIn={isLoggedIn} /> } />
                    <Route path="/Question" render={(props) => <Question {...props} statusChanged={this.loginChanged} isLoggedIn={isLoggedIn} /> } />
                    <Route path="/ConfirmEmail" render={(props) => <ConfirmEmail {...props} statusChanged={this.loginChanged} isLoggedIn={isLoggedIn} /> } />
                    <Route path="/ResetPassword" render={(props) => <PasswordResetForm /> } />
                    <Route path="/RequestEmailLink" render={(props) => <RequestEmailConfirmForm {...props} statusChanged={this.loginChanged} isLoggedIn={isLoggedIn} /> } />                    
                    <Route path="/TnC" render={(props) => <TnC {...props} statusChanged={this.loginChanged} isLoggedIn={isLoggedIn} /> } />
                    <Route path="/Blog" render={(props) => <BlogContainer {...props} statusChanged={this.loginChanged} isLoggedIn={isLoggedIn} /> } />
                    {
                        /* 
                        temp promotion path
                        <Route path="/Event" render={(props) => <EventContainer /> } />
                        */
                    }
                    <Route component={NotFound}/>
                    
                </Switch>                
            </div>
        );
    }
};

export default withRouter(MainContainer);