import React from 'react';
import './MainContent.css';

import {
    FloatingMenu,
    MainButton,
    ChildButton,
  } from 'react-floating-button-menu';

import { FcUndo } from "react-icons/fc";
import { BsList, BsTriangle, BsLayoutTextSidebarReverse } from "react-icons/bs";

class ScrollTopArrow extends React.Component
{

    constructor(props) {
        super(props);
        
        this.state = {
            showScroll:false,
            isOpen: false
        };

        this.checkScrollTop = this.checkScrollTop.bind(this);
    } 
    
    componentDidMount() 
    {
        window.addEventListener('scroll', this.checkScrollTop);
    }
    
    componentWillUnmount() 
    {
        window.removeEventListener('scroll', this.checkScrollTop);
    }

    checkScrollTop()
    {
        const {showScroll} = this.state;

        if (!showScroll && window.pageYOffset > 100)
        {
            this.setState({ showScroll:true })
        } 
        else if (showScroll && window.pageYOffset <= 100)
        {
            this.setState({ showScroll:false })
        }

    };   

    render()
    {
        const {showScroll} = this.state;
        return(
            <div className="scrollTop" style={{height: 150, display: showScroll ? 'flex' : 'none'}}>
            
            <FloatingMenu
                slideSpeed={500}
                direction="up"
                spacing={8}
                isOpen={this.state.isOpen}
            >
                <MainButton
                iconResting={<BsList style={{ fontSize: 30, color:'white'}} />}
                iconActive={<FcUndo style={{ fontSize: 20 }}  />}
                backgroundColor="black"
                onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                size={56}
                style={{backgroundColor:'#0375b4', marginLeft:10}}
                />
                <ChildButton
                icon={<BsLayoutTextSidebarReverse style={{ fontSize: 20 }} />}
                backgroundColor="white"
                size={40}
                onClick={this.props.openTree}
                className="moblie_floating_button"
                />
                <ChildButton
                icon={<BsTriangle style={{ fontSize: 20 }}  />}
                backgroundColor="black"
                size={40}
                className="moblie_floating_button"
                onClick={this.props.goToTop}
                />
                
            </FloatingMenu>
          </div>
        );
    }
    
};

export default ScrollTopArrow;