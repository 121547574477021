import React from 'react';
import { Table, Loader } from 'semantic-ui-react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from '../../Components/Message/Message';
import Logger from '../../Helper/Logger';

class PurchaseHistory extends React.Component{
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {                       
            history:null ,
            messageType:'info',
            messageShow:false,
            message:'',
            spinner:false                    
        };
        
        this.getHistory = this.getHistory.bind(this);
        this.toggleMessage = this.toggleMessage.bind(this);
        this.handleClose = this.handleClose.bind(this);
        
    }

    get displayName() {
        return 'Purchase';
    }

    buttonToggle()
    {
        const {emailValid, email} = this.state;

        if(emailValid && email.length > 0 )
        {
            this.setState({buttonDisable:false});
        }
        else{

            this.setState({buttonDisable:true});
        }
    }

    toggleMessage(mes)
    {
        let {messageType, messageShow, message} = this.state;

        messageType = mes.type;
        messageShow = mes.show;
        message = mes.message;
        
        this.setState({
            message:message,
            messageShow:messageShow,
            messageType:messageType, 
            spinner:false
        });
    }

    handleClose()
    {
        this.setState({
            messageShow:false
        });
    }

    async componentDidMount() {
        this._isMounted = true;
        this.setState({spinner:true});
        await this.getHistory();
    } 

    componentWillUnmount() {
        this._isMounted = false;
    }
    
    async getHistory()
    {        
        const url = process.env.REACT_APP_API_URL + "Controllers/Purchase/GetAllPurchaseHistory.php";
        
        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        })
        .then((response) => {
            if (response.ok) {
                return response.json();                
            } else {
                let s = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                }        
                this.toggleMessage(s);  

                Logger('PurchaseHistory', 'getHistory', response.status + response.statusText + response.url , localStorage.getItem("email"));
            }
        })
        .then((data) => {
            if (this._isMounted) {
                this.setState({history:data, spinner:false});
            }
        })
        .catch(err => {
            let s = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }    
            this.toggleMessage(s); 

            Logger('PurchaseHistory', 'getHistory', 'error message ' + err , localStorage.getItem("email"));

        });
        
    }

    renderHistory()
    {
        const list = this.state.history;

        if(!list || list.length === 0)
        {
            return <Table.Row><Table.Cell>no data</Table.Cell></Table.Row>;
        }
        else
        {            
            return list.map((item, idx) => (
                    <Table.Row key={idx}>
                        <Table.Cell>{item.ProductName}</Table.Cell>
                        <Table.Cell>{item.CreateDate}</Table.Cell>
                        <Table.Cell>{item.ExpiryDate}</Table.Cell>
                    </Table.Row>          
                )
            );
        }
    }

    render(){
        let {message, messageType, messageShow, spinner} = this.state;

        return (   
            <div>  
                <Loader active={spinner}  size='massive'/>                
                <Table unstackable>
                    <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Product</Table.HeaderCell>
                        <Table.HeaderCell>Purchase Date</Table.HeaderCell>
                        <Table.HeaderCell>Expiry Date</Table.HeaderCell>
                    </Table.Row>
                    </Table.Header>

                    <Table.Body>                
                    {this.renderHistory()}                                 
                    </Table.Body>
                </Table>
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={messageShow}
                    autoHideDuration={6000}
                    onClose={this.handleClose}>
                        <SnackbarContentWrapper
                                onClose={this.handleClose}
                                variant={messageType}
                                message={message}
                        />
                </Snackbar>       
            </div>
        );
    }
};

export default PurchaseHistory;