import React from 'react';
import BuyNow from "../../Components/BuyNow/BuyNow";

class BuyNowContainer extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: false
        }
        this.loginChanged = this.loginChanged.bind(this);
    }

    componentDidMount()
    {
        this.setState({isLoggedIn:this.props.isLoggedIn});
    }  
    
    componentWillReceiveProps(nextProps)
    {
        this.setState({isLoggedIn:nextProps.isLoggedIn});
    }

    loginChanged(status)
    {
        this.setState({isLoggedIn:status}, this.props.statusChanged(status));
    }

    render(){
        return (  
            <div>
                <BuyNow isLoggedIn={this.state.isLoggedIn} statusChanged={this.loginChanged}/>
            </div>             
        );
    }
};

export default BuyNowContainer;