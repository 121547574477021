import React from 'react';
// import Comments from './Comment';
import {withRouter} from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import DocumentContent from "../MainContent/DocumentContent";
import './Document.css';
import Logger from '../../Helper/Logger';
import PaidOnly from '../../images/paidOnly.png';
import Highlighter from 'react-highlight-words';

class Document extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            document:{
                documentId:0,
                contentTitle:'Cambridge IELTS',
                openType:'',
                locationId:'',
                filePath:'',
                folderName:'',
                imageFiles:[],
                SeriesId:0,
                TestType:''
            },
            contentFiles:[],
            isLoggedIn: false,
            voca:null,
            isVoca:false
        };
        
        this.updateDocument = this.updateDocument.bind(this);
        this.renderFiles = this.renderFiles.bind(this);
        this.loginChanged = this.loginChanged.bind(this);
        this.updateDocumentByState = this.updateDocumentByState.bind(this);
        this.updateDocumentWithVoca = this.updateDocumentWithVoca.bind(this);
        this.getNewLine = this.getNewLine.bind(this);
    }

    get displayName() {
        return 'Document';
    }

    componentDidMount()
    {
        this.setState({isLoggedIn:this.props.isLoggedIn});
    }      
    
    async componentWillReceiveProps(nextProps)
    {
        let document = this.state.document;
        let isVoca = false;

        if(document.documentId !== nextProps.data.DocumentId)
        {
            document.documentId = nextProps.data.DocumentId;
            document.contentTitle = nextProps.data.ContentTitle;
            document.openType = nextProps.data.OpenType;
            document.locationId = nextProps.data.LocationId;
            document.filePath = nextProps.data.FilePath;
            document.folderName = nextProps.data.FolderName;
            document.imageFiles = nextProps.data.imageFiles;
            document.SeriesId = nextProps.data.SeriesId;
            document.TestType = nextProps.data.TestType;

            if(document.folderName !== undefined && document.folderName.length > 2)
            {
                let checkVal = document.folderName.substring(document.folderName.length-2, document.folderName.length);
                if(checkVal === 'VO')
                {
                    isVoca = true;
                    this.setState({isVoca:true});
                }
                else
                {
                    isVoca = false;
                    this.setState({isVoca:false});
                }
            }
            
            if(isVoca)
            {
                this.setState({document}, await this.updateDocumentWithVoca());
            }
            else
            {
                this.setState({document}, await this.updateDocument());
            }
            
        }

        this.setState({isLoggedIn:nextProps.isLoggedIn});
    }

    async updateDocumentWithVoca()
    {
        let {document} = this.state;
        const url = process.env.REACT_APP_API_URL + "api/Documents/GetVocaContent";

        const s = {
            OpenType:document.openType,
            FilePath:document.filePath,
            FolderName:document.folderName,
            FileName:"",
            SeriesId:document.SeriesId,
            TestType:document.TestType,
            MenuId:document.documentId
        };

        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body:JSON.stringify(s)
        })
        .then((response) => {
            if (response.ok) {
                return response.json();                
            } else {
                let s = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                }        
                this.toggleMessage(s);  

                Logger('Document', 'updateDocumentWithVoca', response.status + response.statusText + response.url , localStorage.getItem("email"));
            }
        })
        .then((data) => {
            this.setState({voca:data});
            this.setState({isVoca:true});
            this.props.isLoaded(true);
            
        })
        .catch(err => {
            let s = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }    
            this.props.onAlert(s);  

            Logger('Document', 'updateDocumentWithVoca', 'error message ' + err , localStorage.getItem("email"));

        });
    }

    async updateDocument()
    {
        let {document} = this.state;

        let tempArray = [];
        let isError = false;

        const url = process.env.REACT_APP_API_URL + "Controllers/Document/GetImageContent.php";
        
        for(const [index, value] of document.imageFiles.entries()) /* eslint-disable-line */
        {
            const s = {
                OpenType:document.openType,
                FilePath:document.filePath,
                FolderName:document.folderName,
                FileName:value,
                SeriesId:document.SeriesId,
                TestType:document.TestType,
                MenuId:document.documentId,
                email:localStorage.getItem("email")
            };


            if(!isError)
            {                          
                await fetch(url, {
                    method:"POST",
                    mode: 'cors',
                    headers:{
                        "Content-Type": "application/json",
                        'Accept': 'application/json',
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                    body:JSON.stringify(s)
                })
                .then((response) => {                    /* eslint-disable-line */
                    //console.log(response.json());
                    if (response.ok) {
                        return response.blob();                    
                    } else {
                        isError = true;
                        Logger('Document', 'updateDocument', response.status + response.statusText + response.url , localStorage.getItem("email"));
                    }
                })
                .then((data) => {
                    tempArray.push(URL.createObjectURL(data));
                })
                .catch(err => {  /* eslint-disable-line */                    
                    isError = true;

                    Logger('Document', 'updateDocument', 'error message ' + err , localStorage.getItem("email"));
                });
            
            }   
        }

        if(isError)
        {
            let s = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }

            this.props.onAlert(s);  
        }
        if(!isError && tempArray.length > 0 )
        {
            this.setState({contentFiles: tempArray});
            this.setState({isVoca:false});
            this.props.isLoaded(true);
        }                                   
    }

    async loginChanged(status)
    {
        this.setState({isLoggedIn:status}, await this.updateDocumentByState(status));
    }

    async updateDocumentByState(status)
    {
        this.props.statusChanged(status);
        if(status) await this.updateDocument();
    }
    
    renderFiles(){
        const {contentFiles} = this.state;
        let table = [];
        let children = [];
        let count = 1;
                
        for(const [index, value] of contentFiles.entries())
        {   
            children.push(<td key={value}><img src={value} alt={index}/></td>)
            
            if(count % 3 === 0)
            {
                table.push(<tr key={index}>{children}</tr>);
                children = [];  
            };
            
            count++;
            
        }
        return table;
    }

    renderDocumentTitle(t)
    {
        let title;
        if(t != null && t.length > 0)
        {
            var arrTemp = t.split('|');
            title = <p>{arrTemp[0]}<br></br>{arrTemp[1]}</p>
        }
        else{
            title="<p></p>";
        }
        return title;
    }

    renderVocaContent()
    {
        const {voca} = this.state;
        let content;

        if(!voca || voca.length === 0)
        {
            return <tbody><tr><td><img src={PaidOnly} alt="PaidOnly"/></td></tr></tbody>;
            
        }

        let passageName = voca[0].PassageName;        

        content=<tbody>
                    <tr><td className='voca_passage_name'>{passageName}<br></br><br></br></td></tr>                                  
                    {this.getWords()}                                                     
                </tbody>

        return content;
    }

    getWords()
    {
        const {voca} = this.state;
                   
        return voca.map((item, idx) => (
                <tr key={idx}>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td><span className='voca_word'>{item.Word}{this.getSimilarWord(item.Similar.trim())}&nbsp;</span>
                                    {item.Pronounce}
                                    </td>                                
                                </tr>
                                <tr>
                                    <td >
                                        {this.getNewLine(item.Korean)}<br></br>
                                        {this.getHighlightWords(item.Example, item.Word, item.Similar, item.Var1, item.Var2)}  
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>                    
                </tr>          
            )
        );
        
    }

    getHighlightWords(example, word, similar, var1, var2)
    {
        
        let highlighted= example.split('\n').map((item, idx) => (            
            <span key={idx}>                                    
                <Highlighter
                    highlightClassName="vaca_highlight_word"
                    searchWords={[word.trim(), similar.trim(), var1.trim(), var2.trim()]}
                    autoEscape={true}
                    textToHighlight={item}
                />
                <br></br>
            </span>
           )
        );

        return highlighted;
    }

    getNewLine(str)
    {        
        return str.split('\n').map((item, idx) => (
             <span key={idx}>{item}<br></br></span>
            )
        );
    }

    getSimilarWord(item)
    {        
        let word="";
        if(item !== null && item.length > 0)
        {
            word = <span>(={item})</span>
        }

        return word;
    }

    render(){
        const {document, isLoggedIn, isVoca} = this.state;
       
        let content ;

        if(document.documentId > 0)
        {
            content = <div>
                        <div className="document_title">{reactStringReplace(document.contentTitle, '#', (m, i) => (<span key={i}><br/></span>))}</div>
                        <table cellPadding="0" cellSpacing="0" width="600px">                            
                            {(isVoca) ? (this.renderVocaContent()) : (<tbody>{this.renderFiles()}</tbody>)}                            
                        </table>
                        <br/><br/><br/>
                        <div>{/* <Comments document={document.documentId} isLoggedIn={isLoggedIn} statusChanged={this.loginChanged} /> */} </div>
                        <br/><br/><br/>
                    </div>
        }
        else{
            content = <DocumentContent />
        }

        return (
            <div>
                {content}                
            </div>
        );
    }
};

export default withRouter(Document);