import React from 'react';
import { TextArea, Button, Form, Grid, Header, Message, Segment, Loader, Icon } from 'semantic-ui-react'
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from '../../Components/Message/Message';
import Modal from 'react-bootstrap/Modal';
import { Redirect} from 'react-router-dom';
import Logger from '../../Helper/Logger';

class Unregister extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            checkpassword:'',
            reason:'',
            GoToMain:false,
            messageType:'info',
            messageShow:false,
            message:'',
            successShow:false,
            buttonDisable:true,
            spinner:false            
        };
        
        this.handleChanged = this.handleChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleMessage = this.toggleMessage.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.goToMain = this.goToMain.bind(this);
        this.buttonToggle = this.buttonToggle.bind(this);
    }

    get displayName() 
    {
        return 'Unregister';
    }

    handleChanged(event)
    {
        this.setState({
            [event.target.id]: event.target.value
        }, this.buttonToggle);      
    }

    
    buttonToggle()
    {
        const {reason, checkpassword } = this.state;
        
        if(reason && checkpassword)
        {
            this.setState({buttonDisable:false});
        }
        else
        {
            this.setState({buttonDisable:true});
        }
    }

    toggleMessage(mes)
    {
        let {messageType, messageShow, message} = this.state;

        messageType = mes.type;
        messageShow = mes.show;
        message = mes.message;
        
        this.setState({
            message:message,
            messageShow:messageShow,
            messageType:messageType, 
            spinner:false
        });
    }

    handleClose()
    {
        this.setState({
            messageShow:false
        });
    }

    goToMain()
    {
        //redirect to login page
        this.setState({GoToMain:true});
        this.props.statusChanged(false);
    }
    
    async handleSubmit(event)
    {
        event.preventDefault();
        this.setState({
            buttonDisable:true,
            spinner:true
        });

        let { checkpassword, reason } = this.state;        
            
        const url = process.env.REACT_APP_API_URL + "Controllers/User/UnRegisterUser.php";
        const s = {
            password: checkpassword,
            reasons:reason,
            isSuccess:false,
            email:localStorage.getItem("email")
        };

        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body:JSON.stringify(s)
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                let s = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                }        
                this.toggleMessage(s); 

                Logger('Unregister', 'handleSubmit', response.status + response.statusText + response.url , localStorage.getItem("email"));
            }
        })
        .then((data) => {
            if(data.isSuccess)
            {
                this.setState({
                    message:"Your request has successfully been completed!",
                    successShow:true,
                    spinner:false
                });                 
                localStorage.clear();                
            }
            else
            {
                let result = "";
                if(data.Description)
                {
                    data.Description.forEach(d => {
                        result = result + d;
                    });
                }

                let s = {
                    message:result,
                    type:'error',
                    show:true
                }        
                this.toggleMessage(s); 
            }
        })
        .catch(err => {
            let s = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }    
            this.toggleMessage(s); 

            Logger('Unregister', 'handleSubmit', 'error message ' + err , localStorage.getItem("email"));
        });
    }

    render(){
        let {message, messageType, messageShow, successShow, GoToMain, buttonDisable, spinner} = this.state;
        let redirectComponent;
        
        if(GoToMain)
        {
            redirectComponent = <Redirect to='/' />;
        }
        else
        {
            redirectComponent = <div></div>;
        }

        return (   
            <div>
                {redirectComponent}  
                
                <Grid textAlign='center' verticalAlign='top'>
                    <Grid.Row>
                        <Grid.Column style={{ maxWidth: 500 }}>
                        <Header as='h4' color='teal' textAlign='center'>
                            <div>If you have Premium Membership, <br>
                            </br>you will no longer use it and we don't provide refund for the rest of days
                            <br></br>
                            Once you Unregister.</div>         
                            <div>
                                만일 유료 서비스를 이용하고 계신다면 탈퇴 하신 후 이것을 사용할 수 없게 되며
                                또한 잔여 기간에 대한 환불도 이루어지지 않음을 알려듭니다.
                            </div>
                        </Header> 
                        <Form onSubmit={this.handleSubmit}>                
                            <Segment stacked>
                            <Form.Input
                                fluid
                                icon='lock'
                                iconPosition='left'
                                placeholder='Password'
                                type='password'
                                id="checkpassword" 
                                name="checkpassword" 
                                onChange={this.handleChanged}
                                value={this.state.checkpassword}
                            /> 
                            <Form.Field control={TextArea} 
                                label='Reason' 
                                id="reason" 
                                name="reason"
                                placeholder='Any feedback will be helpful...' 
                                onChange={this.handleChanged}
                                value={this.state.reason}
                                />
                            <Loader active={spinner} />
                            <Button color='teal' fluid 
                                size='large' 
                                disabled={buttonDisable}>
                                    Unregister
                            </Button>                              
                            </Segment>
                            </Form>                           
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                
                <Modal show={successShow} onHide={this.goToMain} aria-labelledby="contained-modal-title-vcenter">  
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                        <Icon name='check circle outline' color='green' /> Successful!
                        </Modal.Title>
                    </Modal.Header>                          
                    <Modal.Body>
                    
                    <Message info>
                        <Message.Header>{message}</Message.Header>
                    </Message>   
                    <Button primary onClick={this.goToMain}>
                    Go to Main page
                    </Button>   
                    </Modal.Body>
                </Modal> 
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={messageShow}
                    autoHideDuration={6000}
                    onClose={this.handleClose}>
                        <SnackbarContentWrapper
                                onClose={this.handleClose}
                                variant={messageType}
                                message={message}
                        />
                </Snackbar>  
            </div>
        );
    }
};

export default Unregister;