import React from 'react';
import { Accordion, Icon } from 'semantic-ui-react'

class FAQ_Eng extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            activeIndex:0
        };
    
        this.handleClick = this.handleClick.bind(this);     
    }

    handleClick(e, titleProps)
    {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
    
        this.setState({ activeIndex: newIndex })
    }

    render(){
        const { activeIndex } = this.state        
        return (   
            <div>                
                <Accordion>
                    <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    Is joining free?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                    <p className="support_content_body_faq_body">
                    Yes. It is free but some contents are open to paid membership only.
                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    How can I join?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1}>
                    <p className="support_content_body_faq_body">
                    Joining is realy simple. Email address is ID here. All you need to do is to input a valid email address and password you will use.  

                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    Why is email address necessary for signing up?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 2}>
                    <p className="support_content_body_faq_body">
                    Email plays a key role as ID in this site and we send Reset Password link to the email when you forgot the password.
                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 3} index={3} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    I don't have any email. What should I do?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 3}>
                    <p className="support_content_body_faq_body">
                    You can use any email that you can receive ordinary emails. Why don't you create an email this time?

                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 4} index={4} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    Can I change my email address after signing up?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 4}>
                    <p className="support_content_body_faq_body">
                    No. changing email address after signing up is not allowed.
                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 6} index={6} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    I forgot password</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 6}>
                    <p className="support_content_body_faq_body">
                    You can request to send Reset Password link in Login page by clicking Reset Password, then we will send the link to the email address that you used signing up.

                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 7} index={7} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    How can I unregister?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 7}>
                    <p className="support_content_body_faq_body">
                    We are sorry to hear that. You can unregister on My page.
                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 8} index={8} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    Can I join again after unregistration?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 8}>
                    <p className="support_content_body_faq_body">
                    Yes. You can join again but if you used paid service before unregistration, you won't be able to use the service again even though the service is still valid because your purchase history will be deleted while unregistring.

                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 9} index={9} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    Can I get refund when I unregister?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 9}>
                    <p className="support_content_body_faq_body">
                    No. You cannot get refund even when you unregister.

                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 10} index={10} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    Is there any payment methods except Paypal?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 10}>
                    <p className="support_content_body_faq_body">
                    No. We accept Paypal only.

                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 11} index={11} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    I bought paid service but I see Paid membership only notice. What should I do? </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 11}>
                    <p className="support_content_body_faq_body">
                    It could happen when there is very sometimes a time gap sychnronising payment and user information. Then simply Log out and Log in again!

                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 12} index={12} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    Should I join Paypal too?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 12}>
                    <p className="support_content_body_faq_body">
                    If you don't have Paypal account, yod'd better join Paypal.

                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 13} index={13} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    Can I get refund?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 13}>
                    <p className="support_content_body_faq_body">
                    Due to our service is digital content, refund is not possible.

                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 14} index={14} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    Will new Cambridge series be added?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 14}>
                    <p className="support_content_body_faq_body">
                    Yes, when a new Cambridge IELTS series is released, it will be added to our service.

                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 15} index={15} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    When a new Cambridge serides is added, what benefits existing paid members will get?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 15}>
                    <p className="support_content_body_faq_body">
                    We will extend 3 days to all paid membership expiry date.

                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 16} index={16} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    When I have a question, how can I contact you?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 16}>
                    <p className="support_content_body_faq_body">
                    You can send use email on Support page and we will reply within 2 business days.

                    </p>
                    </Accordion.Content>

                    
                </Accordion>   
            </div>
        );
    }
};

export default FAQ_Eng;