import React from 'react';
import MainContent from "../../Components/MainContent/MainContent";
import MainContentMoblie from "../../Components/MainContent/MainContent_mobile";
import { Responsive } from 'semantic-ui-react';

class ContentContainer extends React.Component{
    
    render(){
        return (   
            <div>
                
                <Responsive {...Responsive.onlyComputer}>
                    <MainContent />
                </Responsive>
                <Responsive {...Responsive.onlyTablet}>
                    <MainContent />
                </Responsive>
                <Responsive {...Responsive.onlyMobile}>
                    <MainContentMoblie />
                </Responsive>
            </div>
        );
    }
};

export default ContentContainer;