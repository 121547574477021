import React from 'react';
import NotFoundImage from '../images/404.png';
import './NotFound.css';

class NotFound extends React.Component{
    
    render(){
        return (   
            <div>
                <br></br><br></br><br></br><br></br><br></br>
                <img className="notFound_img" src={NotFoundImage} alt="notFound"/><br></br><br></br>
                <div className="_title">404</div><br></br>
                <div className="_title2">Page not found</div><br></br>
                <div className="_content_text">There's nothing to see here</div>
            </div>
        );
    }
};

export default NotFound;