import React from 'react';
import { Redirect } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from '../../Components/Message/Message';
import Logger from '../../Helper/Logger';

class Logout extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            isLoggedOut:false,
            messageType:'info',
            messageShow:false,
            message:'',
            spinner:false
        };
        
    }

    get displayName() {
        return 'Logout';
    }
    
    async componentDidMount() {
        if(localStorage.getItem("token"))
        {
            const token = localStorage.getItem("token");
            localStorage.clear();

            const url = process.env.REACT_APP_API_URL + "Controllers/User/Logout.php";
            
            await fetch(url, {
                method:"POST",
                mode: 'cors',
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + token
                }
            })
            .then((response) => {
                if(response.ok)
                {
                    this.props.statusChanged(false); 
                    this.setState({isLoggedOut:true});  
                }
                if (!response.ok) {
                    let s = {
                        message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                        type:'error',
                        show:true
                    } 
                    this.toggleMessage(s);    
                    this.props.statusChanged(false);     
                    
                    Logger('LogOut', 'componentDidMount', response.status + response.statusText + response.url , localStorage.getItem("email") );
                }
            })            
            .catch(err => {
                let s = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                }  
                this.toggleMessage(s);
                this.props.statusChanged(false);                
                this.setState({isLoggedOut:true});   

                Logger('Logout', 'componentDidMount', 'error message ' + err , localStorage.getItem("email"));
            });             
            
        }   
           
    }  

    toggleMessage(mes)
    {
        let {messageType, messageShow, message} = this.state;

        messageType = mes.type;
        messageShow = mes.show;
        message = mes.message;
        
        this.setState({
            message:message,
            messageShow:messageShow,
            messageType:messageType, 
            spinner:false
        });
    }

    render(){
        let {message, messageType, messageShow} = this.state;
        return (   
            <div>                
                {this.state.isLoggedOut && 
                    <div>
                        <div>Successfully logged out!</div>         
                        <table>   
                            <tbody>             
                            <tr>
                                <td>
                                    Goodbye! See you again.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <Redirect to='/' />
                    </div>                    
                }
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={messageShow}
                    autoHideDuration={6000}
                    onClose={this.handleClose}>
                        <SnackbarContentWrapper
                                onClose={this.handleClose}
                                variant={messageType}
                                message={message}
                        />
                </Snackbar>  
            </div>
        );
    }
};

export default Logout;