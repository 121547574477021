import React, {createRef} from 'react';
import ReactDOM from 'react-dom';
import LeftMenuList from "../../Components/LeftMenu/LeftMenuList";
import Document from "../../Components/Document/Document";
import "./Content.css";
import {Segment, Responsive, Icon, Loader, Dimmer, Button } from 'semantic-ui-react';
import Container  from 'react-bootstrap/Container';
import Row  from 'react-bootstrap/Row';
import Col  from 'react-bootstrap/Col';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from '../../Components/Message/Message';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import ScrollTopArrow from '../../Components/MainContent/ScrollTopArrow';

class DocumentContainer extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            document:{
                documentId:0,
                contentTitle:'',
                openType:'',
                locationId:'',
                filePath:'',
                folderName:'',
                numberOfFiles:'',
                imageFiles:[],
                SeriesId:0,
                TestType:''

            },
            isLoggedIn: false,
            modalShow: false,
            messageType:'info',
            messageShow:false,
            message:'',
            spinner:false,
            isPaneOpen:false,
            treeApi:null,
            treeData:[]
        };
        
        this.getDocument = this.getDocument.bind(this);
        this.toggleMessage = this.toggleMessage.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.loginChanged = this.loginChanged.bind(this);
        this.startProcess = this.startProcess.bind(this);
        this.docLoaded = this.docLoaded.bind(this);
        this.treeLoad = this.treeLoad.bind(this);
        this.scrollToTop = this.scrollToTop.bind(this);

        this.contextRef = createRef();
        this.openSlide = this.openSlide.bind(this);
    }

    

    get displayName() {
        return 'DocumentContainer';
    }

    componentDidMount() 
    {
        if(!this.state.isLoggedIn && this.props.isLoggedIn)
        {
            this.setState({isLoggedIn:true});
        }
    }

    componentWillReceiveProps(nextProps)
    {
        if(!this.state.isLoggedIn && nextProps.isLoggedIn)
        {
            this.setState({isLoggedIn:true});
        }
    }

    loginChanged(status)
    {
        this.setState({isLoggedIn:status}, this.props.statusChanged(status));
    }

    startProcess()
    {
        this.setState({spinner:true, modalShow:false});
    }

    treeLoad(api, data)
    {
        this.setState({treeApi:api});
        this.setState({treeData:data});
    }

    scrollToTop() 
    {   
        const container = ReactDOM.findDOMNode(this.docContainer);        
        container.scrollIntoView();
    }

    openSlide()
    {
        const {isPaneOpen} = this.state;

        this.setState({isPaneOpen:!isPaneOpen});
    }

    getDocument(doc)
    {
        this.setState({
            document:doc
        });    
    }

    docLoaded(res)
    {
        
        if(res)
        {
            this.setState({spinner:false});
        }
    }

    toggleMessage(mes)
    {
        let {messageType, messageShow, message} = this.state;

        messageType = mes.type;
        messageShow = mes.show;
        message = mes.message;
        
        this.setState({
            message:message,
            messageShow:messageShow,
            messageType:messageType
        });
    }

    handleClose()
    {
        this.setState({
            messageShow:false
        })
    }

    render(){
        let {message, messageType, messageShow, isLoggedIn, spinner, isPaneOpen, treeApi, treeData} = this.state;
        
        if(treeApi)  
        {
            const selected = treeApi.selected();
        }
        
        return (
            <div>            
            <Responsive as={Segment} {...Responsive.onlyComputer}>
            <table className="main_content">   
                <tbody> 
                    <tr>
                        <td>&nbsp;</td>
                        <td width="280"><div className="menu_title">Cambridge IELTS Series</div></td>
                        <td width="620"></td>
                        <td>&nbsp;</td>
                    </tr>            
                    <tr>
                        <td></td>
                        <td id="left-menu">
                            <LeftMenuList onItemClick={this.getDocument} onAlert={this.toggleMessage} onStart={this.startProcess}  onMobile={false}/>
                        </td>
                        <td valign="top" align='left'>                            
                            <Document data={this.state.document} 
                                    onAlert={this.toggleMessage} 
                                    isLoggedIn={isLoggedIn}  
                                    statusChanged={this.loginChanged} 
                                    isLoaded={this.docLoaded}/>
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                </tbody>
            </table>
            <Dimmer active={spinner} inverted><Loader active={spinner}  size='massive'>Loading</Loader></Dimmer>
            </Responsive>
            <Responsive as={Segment} {...Responsive.onlyTablet}>
            <table className="main_content">   
                <tbody> 
                    <tr>
                        <td>&nbsp;</td>
                        <td width="280"><div className="menu_title">Cambridge IELTS Series</div></td>
                        <td width="620"></td>
                        <td>&nbsp;</td>
                    </tr>            
                    <tr>
                        <td></td>
                        <td id="left-menu">
                            <LeftMenuList onItemClick={this.getDocument} onAlert={this.toggleMessage} onStart={this.startProcess} onMobile={false}/>
                        </td>
                        <td valign="top" align='left'>                            
                            <Document data={this.state.document} 
                                    onAlert={this.toggleMessage} 
                                    isLoggedIn={isLoggedIn}  
                                    statusChanged={this.loginChanged} 
                                    isLoaded={this.docLoaded}/>
                        </td>
                        <td>&nbsp;</td>
                    </tr>
                </tbody>
            </table>
            <Dimmer active={spinner} inverted><Loader active={spinner}  size='massive'>Loading</Loader></Dimmer>
            </Responsive>
            <Responsive {...Responsive.onlyMobile}>
                <div ref={ref => this.docContainer = ref} className="document_container_mobile">
                <Dimmer active={spinner} inverted><Loader active={spinner}  size='massive'>Loading</Loader></Dimmer> 
                
                <Segment color='blue' textAlign='center'>Cambridge IELTS Series</Segment>  
                
                <Button
                    primary
                    fluid
                    onClick={this.openSlide}
                    size='medium'
                    >
                    시리즈 선택
                    <Icon name='chevron right'/>
                </Button>                 
                
                         
                <SlidingPane
                    className="some-custom-class"
                    overlayClassName="some-custom-overlay-class"
                    isOpen={isPaneOpen}
                    title="시리즈 선택"
                    subtitle="Select Cambridge IELTS"
                    onRequestClose={() => {
                    // triggered on "<" on left top click or on outside click
                    this.setState({ isPaneOpen: false });
                    }}
                >
                    <Container>
                        <Row>
                        <Col>
                            <LeftMenuList 
                                onItemClick={this.getDocument} 
                                onAlert={this.toggleMessage}  
                                onStart={this.startProcess} 
                                onReady={this.treeLoad} 
                                treeApi={treeApi} 
                                treeData={treeData} 
                                onMobile={true}
                                />
                        </Col>
                        </Row>
                    </Container>      
                </SlidingPane>
                
                    <div className="document_body_mobile">                                                
                        <Document data={this.state.document} 
                                onAlert={this.toggleMessage} 
                                isLoggedIn={isLoggedIn} 
                                statusChanged={this.loginChanged} 
                                isLoaded={this.docLoaded}/>

                        
                    </div>
                    < div className="fab">
                    <ScrollTopArrow goToTop={this.scrollToTop} openTree={this.openSlide}/>
                    </ div>
                </div>                             
            </Responsive>
            <Snackbar
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                open={messageShow}
                autoHideDuration={6000}
                onClose={this.handleClose}>
                    <SnackbarContentWrapper
                            onClose={this.handleClose}
                            variant={messageType}
                            message={message}
                    />
            </Snackbar>
            </div>
        );
    }
};

export default DocumentContainer;