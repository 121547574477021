import React from 'react';
import './BlogContent.css';
import {Segment, Responsive } from 'semantic-ui-react';

class BlogMain extends React.Component{
        
    render(){
        
        return (
            <div>
            <Responsive as={Segment} {...Responsive.onlyComputer}>
            <div className="main_document_container">
                <div className="content_title">IELTS 준비를 위한 자료를 제공합니다<br/><br/></div> 
                <div>  
                    <br></br>                    
                    <p>공부 팁</p>
                    <p>최신 기출 문제</p>
                    <p>라이팅 샘픔</p>
                    <br/><br/>
                    <br/><br/>
                    <p>* 일부 브라우저나 스마트폰에서 페이지가 열리지 않는 경우 PC 에서 크롬 또는 파이어 폭스 브라우저를 사용하시기 바랍니다.</p>
                </div>
            </div> 
            </Responsive>
            <Responsive as={Segment} {...Responsive.onlyTablet}>
            <div className="main_document_container">
                <div className="content_title">IELTS 준비를 위한 자료를 제공합니다<br/><br/></div> 
                <div>  
                    <br></br>                    
                    <p>공부 팁</p>
                    <p>최신 기출 문제</p>
                    <p>라이팅 샘픔</p>
                    <br/><br/>
                    <br/><br/>
                    <p>* 일부 브라우저나 스마트폰에서 페이지가 열리지 않는 경우 PC 에서 크롬 또는 파이어 폭스 브라우저를 사용하시기 바랍니다.</p>
                </div>
            </div> 
            </Responsive>
            <Responsive {...Responsive.onlyMobile}>
            <div className="main_document_container_m">
                <div className="content_title">IELTS 준비를 위한<br></br> <br></br> 자료를 제공합니다<br/><br/></div> 
                <div>  
                    <br></br>                    
                    <p>공부 팁</p>
                    <p>최신 기출 문제</p>
                    <p>라이팅 샘픔</p>
                    <br/><br/>
                    <br/><br/>
                    <p>* 일부 브라우저나 스마트폰에서 <br></br>페이지가 열리지 않는 경우 <br></br>PC 에서 크롬 또는 파이어 폭스 브라우저를 <br></br>사용하시기 바랍니다.</p>
                </div>
            </div> 
            </Responsive>
            </div>
        );
    }
};

export default BlogMain;