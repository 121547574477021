import Logger from '../Helper/Logger';


async function LoginCheck ()
{
    let checkValue = false;

    if(localStorage.getItem("token") === null) return false;
    
    const url = process.env.REACT_APP_API_URL + "Controllers/User/UserLoginCheck.php";
    const s = {
        email: localStorage.getItem("email"),
        token:'',
        Description:'',
        isSuccess:false
    };

    await fetch(url, {
        method:"POST",
        mode: 'cors',
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        body:JSON.stringify(s)
    })
    .then((response) => {   
        if (response.ok) {
            return response.json();
        } else {
            if(response.status !== 409 && response.status !== null)
            {
                Logger('LoginCheck', 'LoginCheck', response.status + response.statusText + response.url , localStorage.getItem("email"));
            }                

            if(response.status === 401 || response.status === 409)
            {                        
                checkValue = false;            
            }  
            localStorage.clear();                
        }
    })
    .then((data) => {
        //if(data !== null || typeof(data) !== 'undefined')
        //{
        //    if(data.isSuccess !== null || typeof(data.isSuccess) !== 'undefined')
        //    {

            //Logger('LoginCheck-data-Notnull', 'LoginCheck-data-ok', 'message ' + data + ' ,', localStorage.getItem("email"));

            if((data !== null) && (data !== undefined || data !== 'undefined' || typeof data !== 'undefined' || typeof data !== undefined))
            {
                if(data && data.isSuccess) 
                {
                    checkValue = true;   
                }
                else
                {
                    localStorage.clear(); 
                    checkValue = false;

                    Logger('LoginCheck-data-null', 'LoginCheck-data', 'error message ' + JSON.stringify(data) + ' ,', localStorage.getItem("email"));
                }
            }
            else{
                Logger('LoginCheck-data-undefined', 'LoginCheck-data', 'error message ' + data + ' ,', localStorage.getItem("email"));
            }

            
        //    }                
        //}
            
        
    })
    .catch(err => {
        
        Logger('LoginCheck-catch', 'LoginCheck-catch', 'error message ' + err , localStorage.getItem("email"));

        localStorage.clear(); 
        checkValue = false;            
    });

    return checkValue;

}

export default LoginCheck;