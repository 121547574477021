import React from 'react';

import { Icon, Loader, Dimmer, Input, Table, Popup, Accordion } from 'semantic-ui-react'
import Button  from 'react-bootstrap/Button';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from '../../Components/Message/Message';
import Logger from  '../../Helper/Logger';
import QuestionFormModal from './QuestionFormModal';

import "./Question.css";

class QuestionList extends React.Component{

    constructor(props) {
        super(props);

        this.state = {
            messageType:'info',
            messageShow:false,
            message:'',
            spinner:false,            
            data:null,
            dataOrigin:null,
            isOpen:false,            
            answer:null,
            activeIndex:0,
            activeLike:0,
            activeDislike:0,
            showForm:false,
            isPaidUser:false,
            isAdminUser:false,
            onLoad:false,
            filter:'',
            isMy:false
        };

        this.getQuestions = this.getQuestions.bind(this);
        this.renderQuestions = this.renderQuestions.bind(this);
        this.getAnswer = this.getAnswer.bind(this);
        this.updateFeedback = this.updateFeedback.bind(this);
        this.updateFeedbackState = this.updateFeedbackState.bind(this);
        this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
        this.handleChanged = this.handleChanged.bind(this);
        this.filterList = this.filterList.bind(this);
        this.showQuestionModal = this.showQuestionModal.bind(this);
        this.setQuestionState = this.setQuestionState.bind(this);
        this.setQuestionFormClosed = this.setQuestionFormClosed.bind(this);
        this.renderQuestionTitle = this.renderQuestionTitle.bind(this);
    }

    componentDidMount() {
         this.setState({
            isMy:this.props.isMy,
            isPaidUser:this.props.paidUser,
            isAdminUser:this.props.adminUser,
            onLoad:this.props.onLoad
        });

        this.getQuestions();        
    }

    componentWillReceiveProps(nextProps)
    {
        this.setState({
            isPaidUser:nextProps.paidUser,
            isAdminUser:nextProps.adminUser,
            isMy:nextProps.isMy,
            onLoad:nextProps.onLoad
        }); 
    }

    toggleMessage(mes)
    {
        let {messageType, messageShow, message} = this.state;

        if(mes.type === 'undefined' || mes.type === undefined) {
            messageType = 'info';
        } else {
            messageType = mes.type;
        }
        
        messageShow = mes.show;
        message = mes.message;
        
        this.setState({
            message:message,
            messageShow:messageShow,
            messageType:messageType, 
            spinner:false
        });
    }

    showQuestionModal(){
        if(this.state.isPaidUser){
            this.setState({showForm:true});
        }        
    }

    setQuestionState(msg){
        this.toggleMessage(msg);

        if(msg.type === "success"){
            this.getQuestions();

            this.setState({
                showForm:false,
                loadList:true
            });
        }        
    }

    setQuestionFormClosed(val)
    {
        this.setState({
            showForm:val
        });
    }

    handleCloseSnackbar()
    {
        this.setState({
            messageShow:false
        });
    }

    updateFeedbackState(item, feedback)
    {
        let {activeLike, activeDislike} = this.state;
        const idx = this.state.data.findIndex( x => x.QuestionId === item.QuestionId);

        let newData = [...this.state.data];

        if(feedback)
        {
            activeLike += 1; 
            this.setState({activeLike:activeLike});
            newData[idx].Like = activeLike;
        }
        else{
            activeDislike += 1; 
            this.setState({activeDislike:activeDislike});
            newData[idx].Dislike = activeDislike;
        }

        this.setState({data:newData});        
    }

    async updateFeedback(event, item, feedback)
    {
        if(!item.IsAnswered) return;

        this.updateFeedbackState(item, feedback);

        event.preventDefault();

        const url = process.env.REACT_APP_API_URL + "Controllers/Question/FeedbackAnswer.php";
        const s = {
            AnswerId:item.AnswerId,
            Like: (feedback) ? 1:0,
            DisLike: (!feedback) ? 1:0
        };

        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body:JSON.stringify(s)
        })
        .then((response) => {  
            if (!response.ok) {
                throw Error(response.statusText);
            } 
        })
        .catch(err => {
            
            let s = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }    
            this.toggleMessage(s); 

            Logger('QuestionList', 'updateFeedback', 'error message ' + err , localStorage.getItem("email"));           
        });
    }
    
    async getAnswer(event, item, id)
    {
        if(item.IsWriter || item.Open || this.state.isAdminUser){
            this.setState({            
                activeIndex:id
            }); 

            if(item.IsAnswered){
                this.setState({
                    activeLike:item.Like,
                    activeDislike:item.DisLike
                }); 
            } 
        } else {
            return;
        }                         
    }

    async getQuestions()
    {
        this.setState({
            buttonDisable:true,
            isProcessing:true,
            spinner:true            
        });  

        let url =(this.state.isMy || this.props.isMy) ?  'Controllers/Question/GetMyQuestions.php' : "Controllers/Question/GetQuestionList.php";
        url = process.env.REACT_APP_API_URL + url;

        const s = {
            email: localStorage.getItem("email")
        };

        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json",
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body:JSON.stringify(s)
        })
        .then((response) => {   
            if (response.ok) {
                return response.json();
            } 
        })
        .then((data) => {
            this.setState({
                data:data, 
                dataOrigin:data,
                spinner:false
            });  
            
        })
        .catch(err => {
     
            let s = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }    
            this.toggleMessage(s); 

            Logger('QuestionList', 'getQuestions', 'error message ' + err , localStorage.getItem("email"));           
        });
    }

    handleChanged(event)
    {
        //let {filter} = this.state;
        this.setState({
            [event.target.id]: event.target.value
        });

        let {dataOrigin} = this.state;

        if(event.target.value === null || event.target.value.length === 0)
        {
            this.setState({data:dataOrigin});
            return;
        }

        if (event.key === 'Enter') this.filterList();

    }

    filterList()
    {        
        let {data} = this.state;
        var check = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
        var check2 = /([^가-힣\x20])/i; 
        var search = this.state.filter;

        if(check.test(search))
        {
            if(!check2.test(search))
            {                
                data = data.filter((x => x.Subject.includes(search)));
            }             
        }
        else
        {
            data = data.filter((x => x.Subject.toLowerCase().includes(search.toLowerCase())));
        }        

        this.setState({data:data});
    }

    renderQuestionTitle(item)
    {
        let rtn = '';        
        
        if(item.Open || this.state.isMy)
        {
            rtn = <span>{item.Subject}</span>
        }
        else
        {
            if(item.IsWriter)
            {
                rtn = <span>{item.Subject}&nbsp;&nbsp;<Icon name='lock' color='grey'/><Icon name='key' color='yellow'/></span>
            }
            else {
                if(this.state.isAdminUser){
                    rtn = <span>{item.Subject}</span>
                } else {
                    rtn = <Popup
                            trigger={
                                <span className="feedback">{item.Subject}&nbsp;&nbsp;<Icon name='lock' color='grey'/></span>        
                            }
                            on='click'
                            content='비공개 질문입니다'
                            >
                            </Popup>  
                }
            }
            
            if(this.state.isAdminUser){
                rtn = <span>{item.Subject}</span>
            }
        }    
  
        return rtn;
    }

    renderQuestionList()
    {
        const list = this.state.data;
        const { activeIndex, isPaidUser, isAdminUser, isMy, activeLike, activeDislike } = this.state;
        
        if(list)
        {
            return list.sort((a, b) => a.QuestionId < b.QuestionId ? 1:-1).map((item, idx) => (
                    <Table.Row key={idx}>
                        <Table.Cell width='1'>{item.QuestionId}</Table.Cell>
                        <Table.Cell width='10'>
                        {(isPaidUser || isAdminUser) ? ( 
                            <Accordion>
                                <Accordion.Title
                                    active={activeIndex === item.QuestionId}
                                    index={item.QuestionId}
                                    onClick={(e) => {this.getAnswer(e, item, item.QuestionId)}}
                                >
                                    {this.renderQuestionTitle(item)}                                          
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === item.QuestionId}>
                                    <div><Icon name='hand paper outline' size='large' color='blue' /> {(isMy) ? '  ' : item.Email}&nbsp;&nbsp;</div><br></br>
                                    {item.QContent}<br></br><br></br>
                                    <div className='answer_body'>
                                        <div>
                                            <Icon name='microphone'  size='large' color='grey'/> {(item.IsAnswered) ? item.AContent:'아직 답변이 없습니다'}
                                        </div> <br></br>
                                        <div>{item.AnsweredBy}  {item.AnsweredDate}</div><br></br>
                                        <div>                    
                                            <span className='feedback' onClick={(e) => {this.updateFeedback(e, item, true)}}><Icon name='thumbs up' color='blue' /></span> {activeLike} &nbsp;&nbsp;
                                            <span className='feedback' onClick={(e) => {this.updateFeedback(e, item, false)}}><Icon name='thumbs down' color='red' /></span> {activeDislike} 
                                        </div>
                                    </div>
                                </Accordion.Content>
                            </Accordion>
                        ):(
                            <Popup
                                trigger={
                                    <span className="feedback">{item.Subject}&nbsp;&nbsp;{(item.Open) ? '' : <Icon name='lock' color='grey'/>}</span>        
                                }
                                on='click'
                                content='유료 회원 전용입니다'
                            >
                            </Popup>                            
                        )} 
                        </Table.Cell>
                        <Table.Cell width='2' align='center'>{item.CreateDate}</Table.Cell>
                        <Table.Cell width='1'>{(item.IsAnswered) ? <Icon name='check circle outline' color='teal'/> : <Icon name='ellipsis horizontal'/>}</Table.Cell>
                    </Table.Row>
                )
            );
        }
        else{
            return <Table.Row>
                        <Table.Cell><span>No Data</span></Table.Cell>
                    </Table.Row>   
        }
    }

    renderQuestions()
    {
        let rtn;
        rtn = <Table color='blue' unstackable>
                <Table.Header>
                <Table.Row >
                    <Table.HeaderCell className='table_header'>No</Table.HeaderCell>
                    <Table.HeaderCell>제목</Table.HeaderCell>
                    <Table.HeaderCell>등록일</Table.HeaderCell>
                    <Table.HeaderCell>답변</Table.HeaderCell>
                </Table.Row>
                </Table.Header>

                <Table.Body>                
                {this.renderQuestionList()}                                 
                </Table.Body>
            </Table>       

        return rtn;
    }

    render(){          
        let {spinner, messageType, messageShow,  message, isMy, showForm, isAdminUser, isPaidUser} = this.state;
        return (   
            <div>
                <Dimmer active={spinner} inverted><Loader active={spinner}  size='massive'>Loading</Loader></Dimmer>
                <br></br>                
                <table className={(isMy || isAdminUser) ? 'table_list_my' : 'table_list'}>
                    <tbody>
                        <tr>
                            <td align='left' width="100">
                            <Popup
                                    content={(!isPaidUser && !isAdminUser) ? '유료 회원 전용입니다' : '질문을 등록합니다'}
                                    on='click'
                                    pinned
                                    trigger={(!isPaidUser && !isAdminUser) ? <Button color='blue'>질문 등록</Button> : <Button color='blue' onClick={this.showQuestionModal} >질문 등록</Button>}
                                />
                                </td>
                            <td>
                                {(!isMy) ?  <div className="support_content_body_3">* 내가 등록한 질문은 마이 페이지에서도 확인 가능합니다!</div> : ''}
                            </td>
                            <td>&nbsp;</td>
                            <td align='right'>
                                <span className='table_list_filter'>
                                <Input
                                    icon={<Icon name='search' color='blue' inverted circular link onClick={(e) => {this.filterList(e)}}/>}
                                    placeholder='Search...'
                                    id="filter" 
                                    name="filter" 
                                    onChange={this.handleChanged}
                                    onKeyPress={this.handleChanged}
                                    value={this.state.filter}
                                />
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>                  
                <div className={(isMy || isAdminUser) ? 'table_list_my' : 'table_list'}>{this.renderQuestions()}</div>
                <br></br><br></br><br></br>
                <QuestionFormModal onCompleted={this.setQuestionState} enable={showForm} onClosed={this.setQuestionFormClosed} />
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={messageShow}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}>
                        <SnackbarContentWrapper
                                onClose={this.handleCloseSnackbar}
                                variant={messageType}
                                message={message}
                        />
                </Snackbar> 
            </div>
        );
    }

};
export default QuestionList;