import React from 'react';
import { Accordion, Icon } from 'semantic-ui-react'

class FAQ_Kr extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            activeIndex:0
        };
    
        this.handleClick = this.handleClick.bind(this);     
    }

    handleClick(e, titleProps)
    {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
    
        this.setState({ activeIndex: newIndex })
    }

    render(){
        const { activeIndex } = this.state        
        return (   
            <div>                
                <Accordion>
                    <Accordion.Title active={activeIndex === 0} index={0} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    회원 가입은 무료인가요?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                    <p className="support_content_body_faq_body">
                    회원 가입은 무료입니다
                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 1} index={1} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    회원 가입은 어떻게 진행되나요?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1}>
                    <p className="support_content_body_faq_body">
                    회원 가입은 간단합니다. 이메일이 아이디 역할을 합니다. 회원 가입 페이지에 사용하실 이메일과 비밀번호를 입력하시면 끝입니다. 
                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 2} index={2} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    회원 가입시 이메일은 왜 필요한가요?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 2}>
                    <p className="support_content_body_faq_body">
                    이메일은 본 사이트에서 회원들의 아이디 역할을 합니다 그리고 비밀번호를 잊었을 경우 비밀번호 초기화를 위한 링크를 이메일로 보내드립니다 

                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 3} index={3} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    회원 가입 이후 이메일을 변경할 수 있나요?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 3}>
                    <p className="support_content_body_faq_body">
                    회원 가입 이후 이메일은 변경할 수 없습니다.
                    </p>
                    </Accordion.Content>

                    <Accordion.Title active={activeIndex === 4} index={4} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    이메일이 없어요 어떻게 해야 하나요?</div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 4}>
                    <p className="support_content_body_faq_body">
                    이메일은 이메일 수신이 가능한 어떤 이메일도 사용하실 수 있습니다. 네이버 구글 등 무료 이메일 서비스가 많으니 이번 기회에 하나 만들어 보세요

                    </p>
                    </Accordion.Content>
                                                            
                    <Accordion.Title active={activeIndex === 6} index={6} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    비밀번호를 잊었습니다
                    </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 6}>
                    <p className="support_content_body_faq_body">
                    로그인 페이지에서 비밀번호 초기화를 클릭하시면 초기화를 할 수 있는 링크를 회원 가입시 사용하셨던 이메일로 보내드립니다. 

                    </p>
                    </Accordion.Content>
                    
                    <Accordion.Title active={activeIndex === 7} index={7} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    회원 탈퇴는 어떻게 하나요?
                    </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 7}>
                    <p className="support_content_body_faq_body">
                    마이페이지에서 가능합니다.

                    </p>
                    </Accordion.Content>
                    
                    <Accordion.Title active={activeIndex === 8} index={8} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    회원 탈퇴 후 재가입이 가능한가요?
                    </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 8}>
                    <p className="support_content_body_faq_body">
                    회원 탈퇴 후 재가입은 가능합니다 하지만 회원 탈퇴 시 구매 정보가 삭제되므로 유료 서비스가 만료되지 않았다하더라도 재가입시에는 기존 유료 서비도 사용하실 수 없습니다.

                    </p>
                    </Accordion.Content>
                    
                    <Accordion.Title active={activeIndex === 9} index={9} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    회원 탈퇴 하면 개인 정보는 어떻게 되나요?
                    </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 9}>
                    <p className="support_content_body_faq_body">
                    회원 탈퇴를 하시면 회원 가입시 사용하신 이메일과 유료 회원의 경우 구매 정보가 모두 삭제됩니다.

                    </p>
                    </Accordion.Content>
                    
                    <Accordion.Title active={activeIndex === 10} index={10} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    회원 탈퇴하면 구매한 상품의 환불을 받을 수 있나요?
                    </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 10}>
                    <p className="support_content_body_faq_body">
                    회원 탈퇴를 하시더라도 구매한 상품의 환불은 이뤄지지 않습니다.

                    </p>
                    </Accordion.Content>
                    
                    <Accordion.Title active={activeIndex === 11} index={11} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    페이팔 이외 다른 방법으로는 결제할 수 없나요?
                    </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 11}>
                    <p className="support_content_body_faq_body">
                    본 사이트는 페이팔 결제만 가능하며 그 외 다른 결제 수단은 제공되고 있지 않습니다.

                    </p>
                    </Accordion.Content>
                    
                    <Accordion.Title active={activeIndex === 12} index={12} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    페이팔에도 회원 가입을 해야 하나요?
                    </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 12}>
                    <p className="support_content_body_faq_body">
                    페이팔 계정이 없는 분들은 페이팔에 회원 가입하시고 사용하시기 바랍니다.

                    </p>
                    </Accordion.Content>
                    
                    <Accordion.Title active={activeIndex === 13} index={13} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    결제 이후에도 유료회원 전용이라는 메시지와 함께 컨텐츠를 볼 수 가 없습니다 어떻게 해야 하나요?
                    </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 13}>
                    <p className="support_content_body_faq_body">
                    결제 정보와 회원 정보를 동기화는 과정에서 약간의 시간 차이가 발생할 수 있습니다. 이런 경우 로그 아웃을 하시고 다시 로그인을 하시면 정상적으로 이용하실 수 있습니다.

                    </p>
                    </Accordion.Content>
                    
                    <Accordion.Title active={activeIndex === 14} index={14} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    상품 구매후 환불은 안되나요?
                    </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 14}>
                    <p className="support_content_body_faq_body">
                    디지털 컨텐츠의 특성상 구매 후 환불은 불가능합니다.
                    </p>
                    </Accordion.Content>
                    
                    <Accordion.Title active={activeIndex === 15} index={15} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    새로운 캠브리지 시리즈는 계속 추가가 되나요?
                    </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 15}>
                    <p className="support_content_body_faq_body">
                    새로운 캠브리지 시리즈가 발매되면 서비스로 추가가 될 예정입니다.

                    </p>
                    </Accordion.Content>
                    
                    <Accordion.Title active={activeIndex === 16} index={16} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    새로운 캠브리지 시리즈가 추가 되면 기존 유료 회원은 손해아닌가요?
                    </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 16}>
                    <p className="support_content_body_faq_body">
                    새로운 캠브리지 시리즈가 추가가 되면 기존 유료 회원들에게는 기존 만료일에 3일을 연장해 드립니다.

                    </p>
                    </Accordion.Content>
                    
                    <Accordion.Title active={activeIndex === 17} index={17} onClick={this.handleClick}>
                    <div  className="support_content_body_faq_title"><Icon name='dropdown' />
                    사이트 이용 관련 문의는 어떻게 해야 하나요?
                    </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 17}>
                    <p className="support_content_body_faq_body">
                    Support 페이지 하단에 양식을 작성해 주시면 이메일로 전달되며 문의 사항에 대한 답변은 2 영업일 안에 이루어집니다.
                    </p>
                    </Accordion.Content>
                    
                </Accordion>   
            </div>
        );
    }
};

export default FAQ_Kr;