import React from 'react';
import TnC from './TnC';
import FAQEng from './FAQ_Eng';
import FAQKr from './FAQ_Kr';
import { Icon, Form, Tab, Loader, Dimmer } from 'semantic-ui-react'
import Modal  from 'react-bootstrap/Modal';
import Container  from 'react-bootstrap/Container';
import Button  from 'react-bootstrap/Button';
import Row  from 'react-bootstrap/Row';
import Col  from 'react-bootstrap/Col';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from '../../Components/Message/Message';
import Logger from  '../../Helper/Logger';
import './MainContent.css';

class ContactUs extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            messageType:'info',
            messageShow:false,
            message:'',
            modalShow: false,
            spinner:false,
            subject:'',
            enquiryMessage:'',
            customerName:'',
            customerEmail:''
        };
     
        this.handleClose = this.handleClose.bind(this);    
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetEmailForm = this.resetEmailForm.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleChanged = this.handleChanged.bind(this);
        this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
    }

    handleClose()
    {
        this.setState({ modalShow: false });
    }

    toggleMessage(mes)
    {
        let {messageType, messageShow, message} = this.state;

        messageType = mes.type;
        messageShow = mes.show;
        message = mes.message;
        
        this.setState({
            message:message,
            messageShow:messageShow,
            messageType:messageType, 
            spinner:false
        });
    }

    resetEmailForm()
    {
        this.setState({
            subject:'',
            enquiryMessage:'',
            customerName:'',
            customerEmail:'',
            spinner:false
        })
    }

    async handleSubmit(event)
    {
        event.preventDefault();
        
        let validate = this.validateForm();

        if(!validate) return;

        const {subject, enquiryMessage, customerName, customerEmail} = this.state;
        
        this.setState({
            buttonDisable:true,
            spinner:true
        });
           
        const url = process.env.REACT_APP_API_URL + "Controllers/Contact/ContactUs.php";
        const s = {
            Subject: subject,
            Message: enquiryMessage,
            SenderName:customerName,
            ReceiveEmail:customerEmail
        };

        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json"
            },
            body:JSON.stringify(s)
        })
        .then((response) => {
            if (response.ok) {
                let s = {
                    message:"Your request has been successfully completed!",
                    type:'success',
                    show:true
                }        
                this.toggleMessage(s); 
                this.resetEmailForm();
            } else {
                
                let s = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                }        
                this.toggleMessage(s); 

                Logger('ContactUs', 'handleSubmit', response.status + response.statusText + response.url , localStorage.getItem("email"));
            }
        })
        .catch(err => {
            let s = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }    
            this.toggleMessage(s); 

            Logger('ContactUs', 'handleSubmit', 'error message ' + err , localStorage.getItem("email"));
        });
    
    }

    handleChanged(event)
    {
        this.setState({
            [event.target.id]: event.target.value
        });
    }

    handleCloseSnackbar()
    {
        this.setState({
            messageShow:false
        });
    }

    validateForm()
    {
        const {subject, enquiryMessage, customerName, customerEmail} = this.state;
        
        let msg = null;
        if(subject.length < 2)
        {
            msg = "Please input subject";
        }
        if(enquiryMessage.length < 2)
        {
            msg = "Please input message";
        }
        if(customerName.length < 2)
        {
            msg = "Please input name";
        }
        if(customerEmail.length < 2)
        {
            msg = "Please input email address";
        }

        if(msg !== null)
        {
            let s = {
                message:msg,
                type:'error',
                show:true
            }    
            this.toggleMessage(s);

            return false;
        }  
        else
        {
            return true;
        }      
    }

    render(){
        const faq =[
            { menuItem: '한국어', render:()=><Tab.Pane><FAQKr /></Tab.Pane>},
            { menuItem: 'English', render:()=><Tab.Pane><FAQEng /></Tab.Pane>}
        ]; 
        
        let {spinner, messageType, messageShow,  message} = this.state;
        return (   
            <div>                
                <div className="main_content_blue_3">
                    <div className="main_content_heading_4_w">
                        <br></br><br></br>도움이 필요하신가요?
                        <br></br><br></br> 메일로 연락주시면 
                        <br></br><br></br>성실히 답변해 드리겠습니다.
                        <br></br><br></br><div className="main_content_heading_5_w"> *일부 메일 서비스에서는 스팸으로 분류될 수  있습니다. 스팸 메일함도 확인해 주세요. </div>
                        <br></br>
                    </div>
                    <div className="support_email_form">
                    <Form inverted size='large' onSubmit={this.handleSubmit}>
                        <Form.Input 
                            label="제목"
                            placeholder='Subject'
                            id="subject" 
                            name="subject" 
                            onChange={this.handleChanged}
                            value={this.state.subject}
                        />
                        <Form.TextArea label='내용' 
                                    placeholder='Tell us more about your problem...' 
                                    id="enquiryMessage" 
                                    name="enquiryMessage" 
                                    onChange={this.handleChanged}
                                    value={this.state.enquiryMessage}
                        />
                        <Form.Input label='이름' 
                                    placeholder='Name' 
                                    id="customerName" 
                                    name="customerName" 
                                    onChange={this.handleChanged}
                                    value={this.state.customerName}
                        />
                        <Form.Input label='답변을 받을 이메일' 
                                    placeholder='Email address' 
                                    id="customerEmail" 
                                    name="customerEmail" 
                                    onChange={this.handleChanged}
                                    value={this.state.customerEmail}
                        />
                        <br></br>
                        <Form.Button color="yellow" size='large'><font color="#000000">메일 보내기</font></Form.Button>
                        <br></br>
                    </Form>
                </div>
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={messageShow}
                    autoHideDuration={6000}
                    onClose={this.handleCloseSnackbar}>
                        <SnackbarContentWrapper
                                onClose={this.handleCloseSnackbar}
                                variant={messageType}
                                message={message}
                        />
                </Snackbar> 
                </div>
                <div className="support_content_heading"><br></br><br></br><br></br>자주 묻는 질문</div>
                <div className="support_content_body">사이트 이용에 궁금하신 점이 있으신가요?</div>  
                <div className="support_content_body_faq_body">
                <Tab panes={faq} />
                </div>      
                <div className="support_content_heading"><br></br><br></br>사이트 이용 약관</div>
                <div className="support_content_body">
                <Button
                    variant="outline-primary"
                    onClick={() => this.setState({ modalShow: true })}
                    size="lg" 
                    >
                    상세 보기
                    <Icon name='chevron right'/>
                </Button>
                </div>
                <Modal show={this.state.modalShow} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter">  
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            사이트 이용 약관
                        </Modal.Title>
                    </Modal.Header>                          
                    <Modal.Body>
                    <Container>
                        <Row>
                        <Col>
                            <TnC />
                        </Col>
                        </Row>
                    </Container>           
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                        닫기
                        </Button>
                        <Button variant="primary" onClick={this.handleClose}>
                        다운받기 
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Dimmer active={spinner} inverted><Loader active={spinner}  size='massive'>Loading</Loader></Dimmer>
                <br></br>
            </div>
        );
    }
};

export default ContactUs;