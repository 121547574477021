import React from 'react';
import ReactDOM from 'react-dom';
import "./BuyNow.css";
import { Button, Grid, Message, Segment, Icon, Item, Divider, Loader, Dimmer, Header, Form, Table } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from '../../Components/Message/Message';
import ModalLoginForm from '../Login/ModalLoginForm';
import { PayPalButton } from 'react-paypal-button-v2';
import Modal  from 'react-bootstrap/Modal';
import Container  from 'react-bootstrap/Container';
import Row  from 'react-bootstrap/Row';
import Col  from 'react-bootstrap/Col';
import TnC from '../MainContent/TnC';
import Logger from '../../Helper/Logger';
import { Responsive } from 'semantic-ui-react';
//import PromoContent from './PromoContent';
//import PromoContentMobile from './PromoContentMobile';

const plans = {
    starter:{
        name:'STARTER',
        duration:60,
        price:19
    },
    plus:{
        name:'PLUS',
        duration:120,
        price:36
    },
    extended:{
        name:'EXTENDED',
        duration:180,
        price:55
    },
    single:{
        name:'SINGLE',
        duration:63,
        price:5
    },
    special:{
        name:'SPECIAL',
        duration:120,
        price:13
    },
    package:{
        name:'PACKAGE',
        duration:180,
        price:19
    },    
    empty:{
        name:'Please select a plan',
        duration:0,
        price:0
    }
};


class BuyNow extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            messageType:'info',
            messageShow:false,
            message:'',
            successShow:false,
            buttonDisable:true,
            showLogInForm:false,
            isLoggedIn: false,
            selectedItem:'empty',
            enableCheckout:false,
            activeButton:'',
            isProcessing:false,
            modalShow: false,  
            selectedSeries:'',
            selectedSeriesName:'',
            selectedTestType:'',
            history:null,
            isExisting:false,
            enablePreceed:true,
            options:[]
        };
        
        this.savePurchase = this.savePurchase.bind(this);
        this.toggleMessage = this.toggleMessage.bind(this);
        this.handleClose = this.handleClose.bind(this);
        
        this.showLoginModal = this.showLoginModal.bind(this);
        this.setLoginState = this.setLoginState.bind(this);
        this.statusChange = this.statusChange.bind(this);

        this.renderCheckout = this.renderCheckout.bind(this);
        this.selectedChange = this.selectedChange.bind(this);
        this.toggleCheckout = this.toggleCheckout.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);

        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.getHistory = this.getHistory.bind(this);
        this.CheckPurchase = this.CheckPurchase.bind(this);
        this.continuePurchase = this.continuePurchase.bind(this);
        this.getOptions = this.getOptions.bind(this);

    }

    componentDidMount() {
        this.getOptions();
        if(!this.state.isLoggedIn && this.props.isLoggedIn)
        {
            this.setState({isLoggedIn:true});
        }
    }

    componentWillReceiveProps(nextProps)
    {
        if(!this.state.isLoggedIn && nextProps.isLoggedIn)
        {
            this.setState({isLoggedIn:true});
        }
    }

    showLoginModal(){
        this.setState({showLogInForm:true});
    }

    setLoginState(){
        this.setState({
            isLoggedIn:true,
            showLogInForm:false
        }, this.statusChange(true));
    }

    statusChange(s)
    {
        this.props.statusChanged(s);
    }

    toggleMessage(mes)
    {
        let {messageType, messageShow, message} = this.state;

        messageType = mes.type;
        messageShow = mes.show;
        message = mes.message;
        
        this.setState({
            message:message,
            messageShow:messageShow,
            messageType:messageType, 
            spinner:false
        });
    }

    handleClose()
    {
        this.setState({
            messageShow:false
        });
    }

    handleModalClose()
    {
        this.setState({ modalShow: false });
    }

    async getOptions()
    {     
        this.setState({
            buttonDisable:true,
            isProcessing:true,
            spinner:true            
        });

        const url = process.env.REACT_APP_API_URL + "Controllers/Purchase/GetOptionList.php";
        
        const result = await fetch(url, {
            method:"GET",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json",
                'Accept': 'application/json'
            }
        })
        .then((response) => {
            if (response.ok) {
                return response.json();                
            } else {
                let s = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                }        
                this.toggleMessage(s);  

                Logger('BuyNow', 'getHistory', response.status + response.statusText + response.url , localStorage.getItem("email"));
            }
        })
        .then((data) => {
            this.setState({
                options:data, 
                spinner:false, 
                isProcessing:false,
                enablePreceed:true
            });            
        })
        .catch(err => {
            let s = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }    
            this.toggleMessage(s); 

            Logger('BuyNow', 'getOptions', 'error message ' + err , localStorage.getItem("email"));

        });

        return result;
        
    }
    
    async savePurchase(product, value)
    {        
        this.setState({
            buttonDisable:true,
            isProcessing:true,
            spinner:true            
        });
        let result;

        const {selectedSeries, selectedTestType} = this.state;
                    
        const url = process.env.REACT_APP_API_URL + "Controllers/Purchase/Purchase.php";
        const s = {
            productName:product.name,
            productDuration:product.duration,
            paypal:value,
            SeriesId:selectedSeries,
            TestType:selectedTestType
        };

        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body:JSON.stringify(s) 
        })
        .then((response) => {
            if (response.ok) {                
                return response.json();
            } else {
                Logger('BuyNow', 'savePurchase', "error " + response.status + response.statusText + response.url, localStorage.getItem("email"));
                result = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                }
            }
        })  
        .then((data) => {
            if(data.isSuccess)
            {
                localStorage.setItem('email', data.Email);
                localStorage.setItem('token', data.Token);

                this.props.statusChanged(true);
                
                result = {
                    message:"Cograturations! Checkout is successfully completed!",
                    type:'success',
                    show:true
                }
                this.setState({
                    successShow:true,
                    isProcessing:false
                });
            }
            else
            {
                let result = "";
                if(data.Description)
                {
                    data.Description.forEach(d => {
                        result = result + d;
                    });
                }

                result = {
                    message:result,
                    type:'error',
                    show:true
                } 

                Logger('BuyNow', 'savePurchase', "data.isSuccess false " + result , localStorage.getItem("email"));
            }
        })      
        .catch(err => {
            result = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }  
            
            Logger('BuyNow', 'savePurchase', 'error message ' + err , localStorage.getItem("email"));
        });

        this.toggleMessage(result);         
    }

    async getHistory()
    {     
        this.setState({
            buttonDisable:true,
            isProcessing:true,
            spinner:true            
        });

        const url = process.env.REACT_APP_API_URL + "Controllers/Purchase/GetPurchaseHistory.php";
        
        const result = await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json",
                'Accept': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem("token")
            }
        })
        .then((response) => {
            if (response.ok) {
                return response.json();                
            } else {
                let s = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                }        
                this.toggleMessage(s);  

                Logger('BuyNow', 'getHistory', response.status + response.statusText + response.url , localStorage.getItem("email"));
            }
        })
        .then((data) => {
            this.setState({
                history:data, 
                spinner:false, 
                isProcessing:false,
                enablePreceed:true
            });            
        })
        .catch(err => {
            let s = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }    
            this.toggleMessage(s); 

            Logger('BuyNow', 'getHistory', 'error message ' + err , localStorage.getItem("email"));

        });

        return result;
        
    }

    selectedChange(event)
    {
        if(event.target.name === 'single')
        {
            if(!(this.state.selectedSeries.length > 0 && this.state.selectedTestType.length > 0))
            {
                let s = {
                    message:'Please select a seriese',
                    type:'error',
                    show:true
                }    
                this.toggleMessage(s); 
                return;
            }
        } else {
            this.setState({
                selectedSeries: '',
                selectedTestType: '',
                selectedSeriesName: ''
            })
        }

        const {enableCheckout} = this.state;
        
        this.setState({
            selectedItem: event.target.name,
            enablePreceed:enableCheckout
        });

    }

    handleSelectChange(event, value)
    {
        const options = value.options;
        let seriesName = '';

        for (const [index, val] of options.entries()) 
        {
            if(val.value === value.value)
            {
                seriesName = val.text;
            }
        }

        var selected = value.value.split('|');
        this.setState({
            selectedSeries: selected[0],
            selectedTestType: selected[1],
            selectedSeriesName: seriesName
        })
    }

    continuePurchase(event, value)
    {
        this.setState({
            enableCheckout:value.checked,
            enablePreceed:value.checked
        });
    }

    toggleCheckout(){
        let { selectedItem} = this.state;

        if(selectedItem )
        {
            this.setState({enableCheckout:true});
        }        
    }
    
    onPaypalClicked(product, value)
    {
        this.setState({
            buttonDisable:true,
            isProcessing:true,
            spinner:true            
        });
    }
    renderCheckout(){
        let {selectedItem, selectedSeriesName} = this.state;
        let rtn;
        
        var p = plans[selectedItem];  
        var payPalId = process.env.REACT_APP_PAYPAL_CLIENT_ID;
        
        rtn = <Segment color='yellow' placeholder>                    
                <Item>
                <Item.Content>
                    <Item.Header as='a'>
                        <div className="item_title">Checkout</div>
                        <Divider />
                    </Item.Header>
                    <Item.Description>
                        <table width='100%'>
                            <tbody>
                                <tr>
                                    <td align='left'>Plan</td>
                                    <td  align='right'><b>{p.name} {p.name==='SINGLE'?selectedSeriesName:''}</b> - {p.duration} days</td>
                                </tr>
                            </tbody>
                        </table> 
                        <table width='100%'>
                            <tbody>
                                <tr>
                                    <td align='left' height="30"><b>Total</b></td>
                                    <td align='right'><b>USD ${p.price}</b></td>
                                </tr>
                            </tbody>
                        </table>     
                        <Divider />                                       
                    <br></br>
                    <br></br>                    
                    <PayPalButton
                        amount={p.price}
                        currency='USD'
                        style={{shape:'pill'}}
                        shippingPreference="NO_SHIPPING"
                        onClick={(res) => this.onPaypalClicked(p, res)}
                        onSuccess={(res) => this.savePurchase(p, res)}
                        onError={(msg) => Logger('Buynow', 'Paypal', msg, localStorage.getItem("email"))}
                        options={{
                            clientId: payPalId,
                            disableFunding:'card'
                        }}
                    />
                    </Item.Description>
                </Item.Content>
                </Item>
                </Segment>       

        return rtn;

    }

    async CheckPurchase()
    {
        //check selected
        if(this.state.selectedItem ==='empty')
        {
            let s = {
                message:'Please select a plan',
                type:'error',
                show:true
            }    
            this.toggleMessage(s); 
            return;
        }

        //check purchase history
        await this.getHistory();
        
        const {history} = this.state;

        if(history.length > 0)
        {
            this.setState({isExisting:true});
        }
        else
        {
            this.setState({enableCheckout:true});
        }

        this.setState({enablePreceed:true});

        const targetNode = ReactDOM.findDOMNode(this.refs.target)
 
        targetNode.scrollIntoView({ behavior: 'smooth'});
 
    }

    renderHistoryList()
    {
        const list = this.state.history;

        return list.map((item, idx) => (
                    <Table.Row key={idx}>
                        <Table.Cell>{item.ProductName}</Table.Cell>
                        <Table.Cell>{item.CreateDate}</Table.Cell>
                        <Table.Cell>{item.ExpiryDate}</Table.Cell>
                    </Table.Row>          
                )
            );
        
    }

    renderHistory()
    {
        let rtn;
        rtn = <Table unstackable>
                <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Product</Table.HeaderCell>
                    <Table.HeaderCell>Purchase Date</Table.HeaderCell>
                    <Table.HeaderCell>Expiry Date</Table.HeaderCell>
                </Table.Row>
                </Table.Header>

                <Table.Body>                
                {this.renderHistoryList()}                                 
                </Table.Body>
            </Table>       

        return rtn;
    }

    render(){
        let {message, messageType, messageShow, showLogInForm, selectedItem, isLoggedIn, successShow, spinner, isProcessing, isExisting, enableCheckout, enablePreceed, options} = this.state;
                
        return (
            <div>
            {isProcessing &&
                <div>                
                    <Dimmer active={isProcessing} page>
                    <Header as='h2' icon inverted>
                        <Loader active={spinner}  size='massive'/>
                        <Icon name='sync' />
                        Please wait...!
                        <Header.Subheader>we are processing</Header.Subheader>
                    </Header>
                    </Dimmer>  
                </div>              
            }                 
            {(successShow) ? ( 
            <div className="content_title">
                <br></br><br></br>
                <p>Checkout is successfully completed!</p>
                <p>We have sent you invocie by email</p>
                <p>You can see details on <Link to="/MyPage"><b><u>My Page</u></b></Link> </p>
                <br></br><br></br>
                <p>Best whishes on your IELTS study</p>
                <br></br><br></br>
                <p>결제가 성공적으로 완료되었습니다!</p>
                <p>이메일로 영수증을 보내드렸습니다</p>
                <p>결제하신 서비스의 내역 및 상세 사항은 <Link to="/MyPage"><b><u>My Page</u></b></Link> 에서 확인하실 수 있습니다</p>
                <br></br><br></br>
                <p>아이엘츠 성공을 기원합니다! 열공하세요~! </p>
            </div>  
            ):(    
            <div className="main_content_purple">
                <br/>   
                <div className="content_title">회원 혜택<br/><br/></div>   
                <br/><br/>  
                <div className="content_body"> 
                    <p>리딩 지문 속 정답 근거 문장 및 설명</p>
                    <p>리딩 지문 해석(캠브리지 시리즈 6 ~ 17)</p>
                    <p>리딩 문제 해석(캠브리지 시리즈 11 ~ 16)</p>
                    <p>리스닝 스크립트 해석(캠브리지 시리즈 6 ~ 17)</p>
                    <p><i>* 인공 지능 번역 기술 향상에 따른 지문 해석 수요 감소에 따라 시리즈 18 부터는 리딩 및 리스닝의 해석이 제공되지 않습니다.</i></p>
                </div>
                <br/><br/>  
                <div>
                    <a href='https://thruielts.blogspot.com/2020/05/go-thru-ielts.html' target='_blank' rel="noopener noreferrer" className='button_yellow'>서비스 미리 보기</a>
                </div>
                <br/><br/>  
                <div className="content_body">  
                    <p>무료 회원은 무료 자료만 이용 가능</p>                    
                    <p>유료 회원은 기간내 모든 자료 이용 가능(단일 시리즈 구매 회원은 해당 시리즈만 이용 가능합니다)</p>
                    <p>상품 구매시 부가세 포함! 추가 요금 없음!</p>
                    <p>이용 기간 만료 후 자동 연장 결제 없음!</p>                    
                </div>
                <br/><br/>  
                <div>
                <Responsive {...Responsive.onlyComputer}>
                    <Grid textAlign='center' verticalAlign='top' container stackable>
                        <Grid.Row columns={1}>
                        <Grid.Column textAlign="center">
                            <Segment piled>
                                <table border="1" cellPadding="2" cellSpacing="2" width="100%">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th height="45" ><div className="item_title">무료회원</div><Divider /></th>
                                            <th><div className="item_title">유료회원</div><Divider /></th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                            <td height="45" width="300"><div className="content_body"> Cambridge IELTS 6 GT/AC</div></td>
                                            <td width="250" align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                            <td width="250" align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 7 GT/AC</div></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 8 GT/AC</div></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 9 GT/AC</div></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 10 GT/AC</div></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 11 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 12 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 13 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 14 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 15 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 16 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 17 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 18 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Segment>
                        </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Responsive>
                <Responsive {...Responsive.onlyTablet}>
                <Grid textAlign='center' verticalAlign='top' container stackable>
                        <Grid.Row columns={1}>
                        <Grid.Column >
                            <Segment piled>
                                <table border="1" cellPadding="2" cellSpacing="2">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th height="45" ><div className="item_title">무료회원</div><Divider /></th>
                                            <th><div className="item_title">유료회원</div><Divider /></th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                            <td height="45" width="300"><div className="content_body"> Cambridge IELTS 6 GT/AC</div></td>
                                            <td width="250" align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                            <td width="250" align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 7 GT/AC</div></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 8 GT/AC</div></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 9 GT/AC</div></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 10 GT/AC</div></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 11 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 12 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 13 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 14 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 15 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 16 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 17 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 18 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Segment>
                        </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Responsive>
                <Responsive {...Responsive.onlyMobile}>
                <Grid textAlign='center' verticalAlign='top' container stackable>
                        <Grid.Row columns={1}>
                        <Grid.Column >
                            <Segment piled>
                                <table border="1" cellPadding="2" cellSpacing="2">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th colSpan="2" align="center"><div className="content_body">회원</div></th>
                                        </tr>
                                        <tr>
                                            <th></th>
                                            <th height="45" ><div className="item_title">무료</div><Divider /></th>
                                            <th><div className="item_title">유료</div><Divider /></th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr>
                                            <td height="45" width="100"><div className="content_body"> Cambridge IELTS 6 GT/AC</div></td>
                                            <td width="100" align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                            <td width="*" align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 7 GT/AC</div></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 8 GT/AC</div></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 9 GT/AC</div></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 10 GT/AC</div></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 11 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 12 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 13 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 14 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 15 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 16 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 17 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                        <tr>
                                            <td height="45"><div className="content_body"> Cambridge IELTS 18 GT/AC</div></td>
                                            <td align="center"><Icon name="cancel" color="red" size="big"></Icon></td>
                                            <td align="center"><Icon name="checkmark" color="olive" size="big"></Icon></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Segment>
                        </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Responsive>
                </div>
                <br/><br/>  
                <div className="content_title">상품 가격<br/><br/></div>  
                <br/><br/>     
                <Grid textAlign='center' verticalAlign='top' container stackable>
                
                    <Grid.Row columns={2}>
                    <Grid.Column >
                    <Segment color='blue'>
                    
                    <Item>
                    <Item.Content>
                        <Item.Header as='a'>
                            <div className="item_title">PACKAGE</div>
                            <Divider />
                        </Item.Header>
                        <Item.Description>
                        <div className="content_price_sp3"> 
                            <br></br>모든 유료 컨텐츠를<br></br><br></br><br></br>
                            </div>
                            <br></br>
                            <div className="content_price_sp_big">USD $19</div>
                            <br></br><br></br>
                            <span className="content_price_sp3">&nbsp;&nbsp;로</span>
                            <br></br><br></br><br></br>
                            <span className="content_price_sp">6 개월</span>
                            <span className="content_price_sp3">&nbsp;&nbsp;동안</span><br></br><br></br>
                            <span className="content_price_sp3">&nbsp;&nbsp;이용할 수 있습니다</span>
                            <br></br>
                            <br></br><br></br><br></br><br></br><br></br>
                        <br></br><br></br>
                        <Button id="package" name="package" basic={selectedItem!=="package"} primary={selectedItem==="package"} color='blue' fluid onClick={this.selectedChange}>
                        SELECT PLAN
                        </Button>
                        <br></br><br></br>
                        </Item.Description>
                    </Item.Content>
                    </Item>
                    </Segment>
                                       
                    </Grid.Column>
                    <Grid.Column>
                        <Segment color='orange'>
                        
                        <Item>
                        <Item.Content>
                            <Item.Header as='a'>
                                <div className="item_title">SINGLE</div>
                                <Divider />
                            </Item.Header>
                            <Item.Description>
                            <div className="content_price_sp3"> 
                            <br></br>선택한 캠브리지 시리즈를<br></br><br></br><br></br>
                            </div>
                            <br></br>
                            <div className="content_price_sp_big">USD $5</div>
                            <br></br><br></br>
                            <span className="content_price_sp3">&nbsp;&nbsp;로</span>
                            <br></br><br></br><br></br>
                            <span className="content_price_sp">9 주</span>
                            <span className="content_price_sp3">&nbsp;&nbsp;동안</span><br></br><br></br>
                            <span className="content_price_sp3">&nbsp;&nbsp;이용할 수 있습니다</span>
                            <br></br>
                            <br></br><br></br>
                            <Form.Select
                                fluid
                                label='Cambridge IELTS 시리즈 선택'
                                options={options}
                                placeholder='Please Select'
                                onChange={this.handleSelectChange}
                            />
                            <br></br>
                            <br></br>
                            <Button id="single" name="single" basic={selectedItem!=="single"} primary={selectedItem==="single"} color='blue' fluid onClick={this.selectedChange}>
                            SELECT SINGLE PLAN
                            </Button>
                            <br></br><br></br>
                            </Item.Description>
                        </Item.Content>
                        </Item>
                        </Segment>
                    </Grid.Column>
                  
                    </Grid.Row>  

                     
                    <Grid.Row columns={1}>
                    <Grid.Column>
                        <Message positive>
                        <div className="content_body">
                            <p><Icon name='info'/>Please be aware that we accept <b>Paypal only</b> and have <b>no refund</b> policy 
                            for digital content.<br/>
                            *Please read <button type="button" className="link-button" onClick={() => this.setState({ modalShow: true })}>Terms and Conditions</button> carefully.</p>
                            <p>* <Icon name='paypal' color='blue' /> Paypay 결제만 가능합니다. 그리고 디지털 컨텐츠의 특성상 결제 완료후 환불이 불가능한 점 양해 바랍니다. </p>
                            <p>
                            <button type="button" className="link-button"  onClick={() => this.setState({ modalShow: true })}>사용자 약관 보기</button>
                            </p>                            
                        </div>
                        </Message>                    
                    </Grid.Column>
                    </Grid.Row>                
                    {isLoggedIn && ( 
                    <Grid.Row columns={1}>                     
                    <Grid.Column>
                        <br></br>
                        <div className="content_body"> Please select a plan and click Proceed button below to checkout<br></br><br></br>
                        상품을 선택하시고 아래 Proceed 버튼을 클릭하시면 결제하실 수 있습니다.
                        </div>
                        <br></br>
                        <Button fluid color='orange' onClick={this.CheckPurchase} disabled={enablePreceed}>
                            Proceed
                        </Button>
                    </Grid.Column>                    
                    </Grid.Row>                    
                    )}
                    <div ref="target"><br></br></div>
                    {isExisting && (
                        <Grid.Row columns={1}>                                    
                        <Grid.Column>
                            {this.renderHistory()}<br></br>
                            <Message info>
                                You have item(s) previously purchased but not expired. Do you wish to continue?<br></br><br></br>
                                만료되지 않은 기존 구매 상품이 존재합니다. 계속 진행하시겠습니까?<br></br><br></br>
                            <Form.Checkbox label='Yes, I wish to purchase' onChange={this.continuePurchase} />
                            </Message>
                        </Grid.Column>
                        </Grid.Row>                         
                    )}
                    
                        
                    {enableCheckout && (
                        <Grid.Row columns={1}>                                    
                        <Grid.Column>
                            {this.renderCheckout()}<br></br>
                        </Grid.Column>
                        </Grid.Row>   
                    )}
                    {!isLoggedIn && ( 
                    <Grid.Row columns={1}>                     
                    <Grid.Column>                      
                        <Message warning>
                        <p>Log-in is necessary to buy this membership service. 상품 구입을 위해서는 로그인이 필요합니다</p>
                            <p>Please <Button basic color='blue' content='Log In' onClick={this.showLoginModal} /></p>
                            <p>New to us? Why don't you <Link to="/SignUp"><b><u>Sign Up</u></b></Link> now? </p>
                            <p>새로 오셨어요? 오늘 <Link to="/SignUp"><b><u>회원 가입</u></b></Link> 해 보시는 건 어떨까요? </p>
                        </Message>
                    </Grid.Column>
                    </Grid.Row>
                    )}
                    <Grid.Row columns={1}>  
                    <Grid.Column>
                        <table height='900'><tbody><tr><td></td></tr></tbody></table>
                    </Grid.Column>
                    </Grid.Row>
                </Grid>    
                
            </div>
            )}
            <ModalLoginForm onCompleted={this.setLoginState} enable={showLogInForm} />
            <Snackbar
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                open={messageShow}
                autoHideDuration={6000}
                onClose={this.handleClose}>
                    <SnackbarContentWrapper
                            onClose={this.handleClose}
                            variant={messageType}
                            message={message}
                    />
            </Snackbar>
            <Modal show={this.state.modalShow} onHide={this.handleModalClose} aria-labelledby="contained-modal-title-vcenter">  
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Terms and Conditions
                        </Modal.Title>
                    </Modal.Header>                          
                    <Modal.Body>
                    <Container>
                        <Row>
                        <Col>
                            <TnC />
                        </Col>
                        </Row>
                    </Container>           
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleModalClose}>
                        Close
                        </Button>
                        <Button variant="primary" onClick={this.handleModalClose}>
                        Download 
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
};

export default BuyNow;