import React from 'react';
import { Button, Form, Grid, Header, Message, Segment, Loader, Icon } from 'semantic-ui-react'
import {EmailValidation} from '../TextboxValidator/EmailValidation';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContentWrapper from '../../Components/Message/Message';
import Modal from 'react-bootstrap/Modal';
import { Redirect } from 'react-router-dom';
import Logger from '../../Helper/Logger';

class PasswordResetRequestForm extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            email:'',
            emailValid:true,
            messageType:'info',
            messageShow:false,
            message:'',
            successShow:false,
            buttonDisable:true,
            spinner:false
        };
        
        this.handleChanged = this.handleChanged.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleMessage = this.toggleMessage.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.goToMain = this.goToMain.bind(this);
        this.buttonToggle = this.buttonToggle.bind(this);
    }

    get displayName() {
        return 'PasswordResetRequestForm';
    }


    handleChanged(event)
    {
        this.setState({
            [event.target.id]: event.target.value
        });

        if(event.target.id === 'email')
        {
            this.setState({emailValid:EmailValidation(event.target.value)}, this.buttonToggle)
        } 
    }

    buttonToggle()
    {
        const {emailValid, email} = this.state;

        if(emailValid && email.length > 0 )
        {
            this.setState({buttonDisable:false});
        }
        else{

            this.setState({buttonDisable:true});
        }
    }

    toggleMessage(mes)
    {
        let {messageType, messageShow, message} = this.state;

        messageType = mes.type;
        messageShow = mes.show;
        message = mes.message;
        
        this.setState({
            message:message,
            messageShow:messageShow,
            messageType:messageType, 
            spinner:false
        });
    }

    handleClose()
    {
        this.setState({
            messageShow:false
        });
    }

    goToMain()
    {
        //redirect to login page
        this.setState({GoToMain:true});
    }

    async handleSubmit(event)
    {
        event.preventDefault();

        this.setState({
            buttonDisable:true,
            spinner:true
        });
  
        const url = process.env.REACT_APP_API_URL + "Controllers/User/ResetPasswordLink.php";
        const s = {
            email: this.state.email,
            token:'',
            Description:'',
            isSuccess:false
        };

        await fetch(url, {
            method:"POST",
            mode: 'cors',
            headers:{
                "Content-Type": "application/json"
            },
            body:JSON.stringify(s)
        })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else {
                let s = {
                    message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                    type:'error',
                    show:true
                }        
                this.toggleMessage(s); 

                Logger('PasswordResetRequestForm', 'handleSubmit', response.status + response.statusText + response.url , localStorage.getItem("email"));
            }
        })
        .then((data) => {
            if(data.isSuccess)
            {
                this.setState({
                    message:"Cograturations! Please check out your email!",
                    successShow:true
                });  
            }
            else
            {
                let result = "";
                if(data.Description)
                {
                    data.Description.forEach(d => {
                        result = result + d;
                    });
                }

                let s = {
                    message:result,
                    type:'error',
                    show:true
                }        
                this.toggleMessage(s); 
            }
        })
        .catch(err => {
            let s = {
                message:process.env.REACT_APP_COMMON_ERROR_MESSAGE,
                type:'error',
                show:true
            }    
            this.toggleMessage(s); 

            Logger('PasswordResetRequestForm', 'handleSubmit', 'error message ' + err , localStorage.getItem("email"));
        });
    
    }

    render(){
        let {message, messageType, messageShow, successShow, GoToMain, buttonDisable, spinner} = this.state;
        let redirectComponent;
        
        if(GoToMain)
        {
            redirectComponent = <Redirect to='/' />;
        }
        else
        {
            redirectComponent = <div></div>;
        }

        return (   
            <div>  
                {redirectComponent}  
                <br/><br/><br/> 
                <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='top'>
                    <Grid.Row>
                        <Grid.Column style={{ maxWidth: 500 }}>
                        <Header as='h2' color='teal' textAlign='center'>
                            The Email you signed up is necessary. <br/>We send password reset link to this email.<br/><br/>
                            회원 가입시 사용한 이메일이 필요합니다. <br/>비밀번호를 초기화하는 링크를 <br/>메일로 보냅니다.<br/><br/>
                        </Header> 
                        <Form size='large' onSubmit={this.handleSubmit}>                
                            <Segment stacked>
                            <Form.Input 
                                    fluid 
                                    icon='user' 
                                    iconPosition='left' 
                                    placeholder='Email address' 
                                    value={this.state.email} 
                                    onChange={this.handleChanged} 
                                    id="email" 
                                    name="email"/>
                            
                            {!this.state.emailValid &&
                                <Message  color='red'>
                                    Email address is not valid. Please check this out again.<br/>
                                    이메일 주소가 부정확합니다. 다시 확인해 주십시요.
                                </Message>
                            }
                            
                            <Loader active={spinner} />
                            <Button color='teal' fluid size='large' disabled={buttonDisable}>Password Reset</Button>                              
                            </Segment>
                            </Form>                           
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                
                <Modal show={successShow} onHide={this.goToMain} aria-labelledby="contained-modal-title-vcenter">  
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <Icon name='check circle outline' color='green' />  Successful!  
                        </Modal.Title>
                    </Modal.Header>                          
                    <Modal.Body>
                    
                    <Message info>
                        <Message.Header>{message}</Message.Header>
                    </Message>   
                    <Button primary onClick={this.goToMain}>
                    Go to Main page
                    </Button>   
                    </Modal.Body>
                </Modal>
                <Snackbar
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={messageShow}
                    autoHideDuration={6000}
                    onClose={this.handleClose}>
                        <SnackbarContentWrapper
                                onClose={this.handleClose}
                                variant={messageType}
                                message={message}
                        />
                </Snackbar>  
            </div>
        );
    }
};

export default PasswordResetRequestForm;