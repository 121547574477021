function EmailValidation(email)
{
 
    var mailformat = /^\w+([\.-]?\w+)*@\w+([-]?\w+)*(\.\w{2,4})+$/;/* eslint-disable-line */
    if(email.match(mailformat))
    {
        return true;
    }
    else
    {    
        return false;
    }

}

function PasswordValidation(value) 
{ 
    var passw=  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
    
    if(value.match(passw)) 
    {
        return true;
    }
    else
    { 
        return false;
    }
}

export {EmailValidation, PasswordValidation}