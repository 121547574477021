import React, { Component } from 'react';
import './App.css';
import MainContainer from "./Containers/MainContainer/MainContainer";
import {Helmet} from 'react-helmet';

class App extends Component {

  render(){
    return (
      <div> 
        <Helmet>
          <title>캠브리지 아이엘츠 리딩 리스닝 지문 문제 해석 해설 최신 기출 Go Through IELTS:: Cambridge IELTS Reading Answers Best Self-study materials!</title>
          <meta name="description" content="캠브리지 아이엘츠 18 17 16 15 14 13 12 11 10 9 8 7 6 리딩 리스닝 지문 문제 한국어 해석 해설 해외 영국 미국 호주 캐나다 유학 이민 이주 사업 독해 독학 자습 해설집 자습서 최신 기출 Cambridge IELTS Reading Answers listening" />
        </Helmet>       
          <MainContainer /> 
      </div>
    );
  }
}

export default App;
